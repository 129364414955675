const contactSearchFilterArray = [
  {
    name: "firstname",
    label: "First Name",
    type: "text",
  },
  {
    name: "lastname",
    label: "Last Name",
    type: "text",
  },
  {
    name: "usesoundex",
    label: "Use Sounds Like",
    type: "bit",
  },
  {
    name: "emailaddress1",
    description: "Must be an exact match",
    label: "Email Address",
    type: "text",
  },
];

const existingContactRecordsSearchFilterArray = [
  {
    name: "fullname",
    description: "",
    label: "Name",
  },
  {
    name: "emailaddress1",
    description: "Must be an exact match",
    label: "Email Address",
  },
];

const getDefaultSelectedAttributes = (state) => {
  return {
    firstname: state.firstname,
    middlename: state.middlename,
    lastname: state.lastname,
    emailaddress1: state.emailaddress1,
    gendercode: state.gendercode,
    telephone1: state.telephone1,
    telephone2: state.telephone2,
    mobilephone: state.mobilephone,
    jobtitle: state.jobtitle,
    birthdate: state.birthdate,
    donotemail: state.donotemail,
    donotbulkemail: state.donotbulkemail,
    donotpostalmail: state.donotpostalmail,
    donotbulkpostalmail: state.donotbulkpostalmail,
    donotphone: state.donotphone,
    g4_donotphone3rdparty: state.g4_donotphone3rdparty,
    g4_donotbulkemail3rdparty: state.g4_donotbulkemail3rdparty,
  };
};

export {
  contactSearchFilterArray,
  existingContactRecordsSearchFilterArray,
  getDefaultSelectedAttributes,
};
