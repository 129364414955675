import AddRemoveRecordsTable from "../components/elements/AddRemoveRecordsTable";
import YesNoDefaultValue from "../components/form/productModifiers/YesNoDefaultValue";
import {
  bookableProductsSearchFilterArray,
  bookableProductsTableColumns,
  optionVariantsSearchFilterArray,
  optionVariantsTableColumns,
} from "../components/form/productModifiers/Helpers";
import ModifierOptions from "../components/form/productModifiers/ModifierOptions";
import { ProductModifierType } from "../js/enums";
import lookups from "./lookups";

export const ProductModifierFormStages = [
  {
    title: "Product Modifier",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_productbaseid",
            hidden: true,
          },
          {
            name: "g4b_name",
            className: "col-md-8",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_isrequired",
            className: "col-md-4",
            description: "",
            default: false,
            label: "Is required?",
            type: "bit",
          },
          {
            name: "g4b_type",
            className: "col-md-4",
            description: "",
            label: "Type",
            required: true,
            type: "picklist",
            enum: ProductModifierType,
          },
          {
            name: "g4b_allowcustomoption",
            className: "col-md-4",
            description:
              "If set to Yes, then this will allow the customer to set a free text value. If Modifier Options is empty, this has to be set to Yes.",
            default: false,
            displayIfFromValues: (values) =>
              String(values.g4b_type) ===
              String(ProductModifierType.Text),
            label: "Allow Custom Option",
            type: "bit",
          },
          {
            className: "col-12",
            name: "modifieroptions",
            component: ModifierOptions,
            displayIfFromValues: (values) =>
              String(values.g4b_type) ===
              String(ProductModifierType.Text),
            type: "component",
          },
          {
            className: "col-12",
            name: "yesnodefaultvalue",
            component: YesNoDefaultValue,
            displayIfFromValues: (values) =>
              String(values.g4b_type) ===
              String(ProductModifierType["Yes/No"]),
            type: "component",
          },
          {
            name: "g4b_modifieroptions",
            hidden: true,
          },
          {
            name: "g4b_defaultvalue",
            hidden: true,
          },
        ],
      },
      {
        name: "Option Variants",
        displayIfFromValues: (values) =>
          String(values.g4b_type) ===
          String(ProductModifierType.Options),
        fields: [
          {
            name: "modifieroptionvariantstable",
            className: "col-12",
            component: AddRemoveRecordsTable,
            allowEdit: true,
            existingRecordsEntitySearchName:
              "g4b_productbasemodifieroptionvariants",
            existingRecordsEntitySearchFilter:
              "ignoreresultexceedcheck",
            existingRecordsSearchFilterArray:
              optionVariantsSearchFilterArray,
            lookup: lookups.g4b_varianttype.all,
            type: "component",
            recordsAddValue: "optionsToAdd",
            recordsRemoveValue: "optionsToRemove",
            recordsTableColumns: optionVariantsTableColumns,
            addRecordsEntitySearchName: "g4b_protoproduct",
            addRecordsEntitySearchFilter:
              "filteroutmodifierprotoproduct",
            addRecordsSearchFilterArray:
              optionVariantsSearchFilterArray,
            addRecordsTableColumns: optionVariantsTableColumns,
          },
          { name: "optionsToAdd", hidden: true },
          { name: "optionsToRemove", hidden: true },
          //The following for g4b_varianttypecategory is only here so that the lookup can be loaded
          //into state for use in the above option variants
          {
            name: "g4b_varianttypecategory",
            lookup: lookups.g4b_varianttypecategory.all,
            type: "lookup",
            hidden: true,
          },
        ],
      },
      {
        name: "Bookable Products",
        fields: [
          {
            name: "modifierproductstable",
            className: "col-12",
            component: AddRemoveRecordsTable,
            allowEdit: true,
            existingRecordsEntitySearchName:
              "g4b_productbasemodifierproducts",
            existingRecordsEntitySearchFilter:
              "ignoreresultexceedcheck",
            existingRecordsSearchFilterArray:
              bookableProductsSearchFilterArray,
            lookup: lookups.g4b_productbase.all,
            type: "component",
            recordsAddValue: "productsToAdd",
            recordsRemoveValue: "productsToRemove",
            recordsTableColumns: bookableProductsTableColumns,
            addRecordsEntitySearchName: "g4b_product",
            addRecordsEntitySearchFilter: "filteroutmodifierproduct",
            addRecordsSearchFilterArray:
              bookableProductsSearchFilterArray,
            addRecordsTableColumns: bookableProductsTableColumns,
          },
          { name: "productsToAdd", hidden: true },
          { name: "productsToRemove", hidden: true },
        ],
      },
    ],
  },
];
