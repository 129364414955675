import BlockDesigner from "../components/form/block/BlockDesigner";
import { getSeatNumber } from "../components/form/block/Helpers";
import { SeatNumberDirection, ZigZagStart } from "../js/enums";
import { AreaFormStages } from "./area";
import lookups from "./lookups";
import { VenueFormStages } from "./venue";

export const BlockFormStages = [
  {
    title: "Block",
    mapValuesOnSubmit: async (_, __, submitValues) => {
      const { g4b_rows, g4b_seated, g4b_seats, g4b_seatdata } =
        submitValues;
      if (!g4b_seated || !g4b_seatdata) {
        return submitValues;
      }
      const seatData = JSON.parse(g4b_seatdata);

      // generate the block seat xml
      var output = [];
      output.push('<?xml version="1.0" ?>');
      output.push("<blockseats>");
      let seatNumber = "";
      for (var r = 0; r < g4b_rows; r++) {
        for (var s = 0; s < g4b_seats; s++) {
          seatNumber = getSeatNumber(r, s, seatData);
          if (seatNumber > 0) {
            output.push("<blockseat>");
            output.push(`<row>${seatData.rowLabels[r]}</row>`);
            output.push(`<seat>${seatNumber}</seat>`);
            output.push(
              `<class>${
                seatData.classIndexes[seatData.classes[r][s]]
              }</class>`
            );
            output.push("</blockseat>");
          }
        }
      }
      output.push("</blockseats>");
      return {
        ...submitValues,
        g4b_blockseatxml: output.join(""),
      };
    },
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_code",
            description: "",
            label: "Code",
            type: "nvarchar",
          },
          {
            name: "g4b_venueid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Venue",
            lookup: lookups.g4b_venue.all,
            required: true,
            subFormStages: VenueFormStages,
            type: "lookup",
          },
          {
            name: "g4b_areaid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Area",
            lookup: lookups.g4b_area.all,
            subFormStages: AreaFormStages,
            type: "lookup",
          },
          {
            name: "g4b_svg",
            className: "col-12 col-md-8",
            description: "",
            label: "SVG",
            type: "nvarchar",
          },
          {
            name: "g4b_seated",
            description: "",
            label: "Seated",
            type: "bit",
          },
        ],
      },
      {
        name: "Capacity",
        fields: [
          {
            name: "g4b_unseatedcapacity",
            description: "",
            displayIfFromValues: (values) => !values.g4b_seated,
            label: "Unseated Capacity",
            required: true,
            type: "int",
          },
        ],
      },
      {
        name: "Seat Layout",
        fields: [
          {
            name: "g4b_rows",
            description: "",
            default: 10,
            displayIfFromValues: (values) => values.g4b_seated,
            label: "Rows",
            type: "int",
          },
          {
            name: "g4b_seats",
            description: "",
            default: 10,
            displayIfFromValues: (values) => values.g4b_seated,
            label: "Seats",
            type: "int",
          },
          {
            name: "g4b_radius",
            description:
              "Defines the block curvature. Enter a value of 10,000 or more for a straight line, or a smaller value for a curved layout.",
            default: 10000,
            displayIfFromValues: (values) => values.g4b_seated,
            label: "Radius",
            type: "int",
          },
          {
            name: "g4b_seatnumberdirection",
            description: "",
            default: SeatNumberDirection["Left to Right"],
            displayIfFromValues: (values) => values.g4b_seated,
            enum: SeatNumberDirection,
            label: "Seat Number Direction",
            type: "picklist",
          },
          {
            name: "g4b_firstseat",
            description: "",
            default: 1,
            displayIfFromValues: (values) => values.g4b_seated,
            label: "First Seat",
            type: "int",
          },
          {
            name: "g4b_ignoregaps",
            description:
              "If yes, seat numbers are allocated ignoring gaps (no seat) i.e. seat numbers either side of a gap will be sequential.",
            default: false,
            displayIfFromValues: (values) => values.g4b_seated,
            label: "Ignore Gaps",
            type: "bit",
          },
          {
            name: "g4b_zigzagstart",
            description: "",
            default: ZigZagStart["Bottom Left"],
            displayIfFromValues: (values) =>
              values.g4b_seated &&
              values.g4b_seatnumberdirection &&
              values.g4b_seatnumberdirection ===
                SeatNumberDirection["Zig Zag"],
            enum: ZigZagStart,
            label: "Zig Zag Start",
            type: "picklist",
          },
          {
            name: "g4b_seatdata",
            className: "col-12",
            component: BlockDesigner,
            displayComponentOnOverview: true,
            displayIfFromValues: (values) => values.g4b_seated,
            type: "component",
          },
          {
            name: "g4b_blockseatxml",
            hidden: true,
          },
        ],
      },
    ],
  },
];
