import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Loading } from "./_Elements";
import { AlertStyle } from "../../js/enums";
import AddNewRecordModal from "./AddNewRecordModal";
import { checkForValidRoles, searchRecords } from "../form/Helpers";
import {
  useGlobalReducer,
  useGlobalState,
} from "../../GlobalContext";
import RecordsTable from "./RecordsTable";
import {
  DeleteIcon,
  MagnifyIcon,
  MinusIcon,
  PlusIcon,
} from "../Icons";
import { getSearchTableFilters } from "./AddRemoveHelper";

function AddRemoveRecordsTable({
  field,
  setFieldValue,
  state,
  values,
}) {
  const globalDispatch = useGlobalReducer();
  const globalState = useGlobalState();

  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [entitySearchResult, setEntitySearchResult] = useState([]);
  const [filteredEntitySearchResult, setfilteredEntitySearchResult] =
    useState([]);

  const [recordsToAdd, setRecordsToAdd] = useState([]);
  const [recordsToRemove, setRecordsToRemove] = useState([]);

  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [remainingRecordsLoaded, setRemainingRecordsLoaded] =
    useState(false);
  const [resultInitialTablePage, setResultInitialTablePage] =
    useState(1);

  const allowAddRoles =
    field && field.allowAddRoles ? field.allowAddRoles : [];
  const addRecordsEntitySearchName =
    field && field.addRecordsEntitySearchName
      ? field.addRecordsEntitySearchName
      : null;
  const addRecordsEntitySearchFilter =
    field && field.addRecordsEntitySearchFilter
      ? field.addRecordsEntitySearchFilter
      : null;
  const addRecordsSearchFilterArray =
    field && field.addRecordsSearchFilterArray
      ? field.addRecordsSearchFilterArray
      : null;
  const addRecordsTableColumns =
    field && field.addRecordsTableColumns
      ? field.addRecordsTableColumns
      : null;
  const existingRecordsEntitySearchName =
    field && field.existingRecordsEntitySearchName
      ? field.existingRecordsEntitySearchName
      : null;
  const existingRecordsEntitySearchFilter =
    field && field.existingRecordsEntitySearchFilter
      ? field.existingRecordsEntitySearchFilter
      : null;
  const existingRecordsSearchFilterArray =
    field && field.existingRecordsSearchFilterArray
      ? field.existingRecordsSearchFilterArray
      : null;
  const recordsTableColumns =
    field && field.recordsTableColumns
      ? field.recordsTableColumns
      : null;
  const recordsAddValue =
    field && field.recordsAddValue ? field.recordsAddValue : null;
  const recordsRemoveValue =
    field && field.recordsRemoveValue
      ? field.recordsRemoveValue
      : null;

  const allowAdd =
    addRecordsEntitySearchName &&
    addRecordsEntitySearchFilter &&
    addRecordsSearchFilterArray &&
    addRecordsTableColumns &&
    (!allowAddRoles ||
      allowAddRoles.length === 0 || //If no roles specified then allow for all roles
      checkForValidRoles(allowAddRoles, globalState));
  const allowEdit =
    field && field.allowEdit ? field.allowEdit : false;

  useEffect(() => {
    if (initialLoad || values.updateAction === "refresh") {
      let filterObject = {};
      filterObject["parentId"] = state.id;
      filterObject["maxresults"] = 200;
      filterObject["searchfilter"] =
        existingRecordsEntitySearchFilter;

      searchRecords(
        existingRecordsEntitySearchName,
        filterObject,
        initialLoad ? null : globalDispatch, //Sending a null globalDispatch so that we don't see a no records found message
        //when loading the page on a new creation
        setEntitySearchResult,
        setEntitySearchResult,
        setLoading,
        setIsSubmitting
      );
      setInitialLoading(true);
      setResultInitialTablePage(1);
      setRemainingRecordsLoaded(false);

      //Clear the add and remove tables after clicking the save button and reload the records
      if (values.updateAction === "refresh") {
        setFieldValue("updateAction", "");
        setFilters([]);
        setRecordsToAdd([]);
        setRecordsToRemove([]);
      }
    }
  }, [
    existingRecordsEntitySearchName,
    existingRecordsEntitySearchFilter,
    globalDispatch,
    initialLoad,
    state.id,
    values.updateAction,
    setFieldValue,
  ]);

  const handleSubmitFilter = () => {
    let filterObject = {};
    let filterArray = [];
    setEntitySearchResult([]);
    setIsSubmitting(true);

    let noFilterValueSet = true;
    existingRecordsSearchFilterArray.forEach((filter) => {
      let key = filters.filter((f) => f.Key === filter.name);
      let value = "";
      if (key.length === 1) {
        value = key[0].Value;
        if (key[0].Value) {
          noFilterValueSet = false;
        }
      }

      // use filter key, if defined, else fallback to filter name
      const filterKey = filter.key ? filter.key : filter.name;
      filterArray.push({
        Key: filter.name,
        Value: value,
      });

      if (filter.type === "select") {
        filterObject[filterKey] = value.toString().toLowerCase();
      } else {
        filterObject[filterKey] = value;
      }
    });

    //If no values were set in the filter, then load the first 200 results
    //like with when the page is initially loaded
    if (noFilterValueSet) {
      filterObject["maxresults"] = 200;
      filterObject["searchfilter"] =
        existingRecordsEntitySearchFilter;
    }

    filterArray.push({
      Key: "parentId",
      Value: state.id,
    });
    filterObject["parentId"] = state.id;

    setFilters(filterArray);
    searchRecords(
      existingRecordsEntitySearchName,
      filterObject,
      globalDispatch,
      setEntitySearchResult,
      setEntitySearchResult,
      setLoading,
      setIsSubmitting
    );
    setResultInitialTablePage(1);
    setRemainingRecordsLoaded(false);
  };

  const loadRemainingRecords = (currentPage) => {
    if (!remainingRecordsLoaded) {
      let filterObject = {};
      filterObject["parentId"] = state.id;
      filterObject["maxresults"] = totalRecords;
      filterObject["searchfilter"] =
        existingRecordsEntitySearchFilter;
      searchRecords(
        existingRecordsEntitySearchName,
        filterObject,
        globalDispatch,
        setEntitySearchResult,
        setEntitySearchResult,
        setLoading,
        setIsSubmitting,
        1,
        totalRecords
      );
      setResultInitialTablePage(currentPage);
      setRemainingRecordsLoaded(true);
    }
  };

  const addNewRecordsToParentEntity = (newRecords) => {
    let updatedList = recordsToAdd;

    newRecords.forEach((record) => {
      if (!updatedList.some((m) => m.Id === record.Id)) {
        record.Fields["createdon"] = "";
        updatedList = [...updatedList, record];
      }
    });
    setRecordsToAdd(updatedList);
    setFieldValue(recordsAddValue, updatedList);
    setDisplayAddModal(false);
  };

  const removeRecordFromParentEntity = (record) => {
    let updatedList = [...recordsToRemove, record];
    setRecordsToRemove(updatedList);
    setFieldValue(recordsRemoveValue, updatedList);
  };

  const removeNewRecordFromAddList = (record) => {
    let updatedList = recordsToAdd.filter((m) => m.Id !== record.Id);
    setRecordsToAdd(updatedList);
    setFieldValue(recordsAddValue, updatedList);
  };

  const removeRecordsFromRemoveList = (record) => {
    let updatedList = recordsToRemove.filter(
      (m) => m.Id !== record.Id
    );
    setRecordsToRemove(updatedList);
    setFieldValue(recordsRemoveValue, updatedList);
  };

  //Filter the entitySearchResult to only have those not in the recordsToAdd or the recordsToRemove
  useEffect(() => {
    let filteredSearchResult = [];
    setTotalRecords(entitySearchResult.length);
    if (recordsToAdd.length > 0 || recordsToRemove.length > 0) {
      entitySearchResult
        .filter((m) => m.Name !== "ResultInfo")
        .forEach((m) => {
          if (
            !recordsToAdd.some((l) => l.Id === m.Id) &&
            !recordsToRemove.some((l) => l.Id === m.Id)
          ) {
            filteredSearchResult = [...filteredSearchResult, m];
          }
        });
    } else {
      filteredSearchResult = entitySearchResult.filter(
        (m) => m.Name !== "ResultInfo"
      );
    }
    setfilteredEntitySearchResult(filteredSearchResult);
  }, [entitySearchResult, recordsToAdd, recordsToRemove]);

  if (loading || values.updateAction === "refresh") {
    return <Loading />;
  }
  return (
    <>
      {displayAddModal && (
        <AddNewRecordModal
          addNewRecordsToParentEntity={addNewRecordsToParentEntity}
          addRecordsEntitySearchName={addRecordsEntitySearchName}
          addRecordsEntitySearchFilter={addRecordsEntitySearchFilter}
          addRecordsSearchFilterArray={addRecordsSearchFilterArray}
          addRecordsTableColumns={addRecordsTableColumns}
          parentId={state.id}
          recordsTableColumns={recordsTableColumns}
          recordsToAdd={recordsToAdd}
          setDisplayAddModal={setDisplayAddModal}
          state={state}
        />
      )}
      <div className="form-table-nav">
        <ul className="nav me-auto">
          <li className="nav-item">
            <span className="text-primary">
              <FontAwesomeIcon icon={MagnifyIcon} className="me-1" />
              Search
            </span>
          </li>
          {allowAdd && (
            <li
              className="nav-item"
              onClick={() => setDisplayAddModal(true)}
            >
              <span>
                <FontAwesomeIcon icon={PlusIcon} className="me-1" />
                Add
              </span>
            </li>
          )}
        </ul>
      </div>
      {getSearchTableFilters(
        filters,
        handleSubmitFilter,
        isSubmitting,
        existingRecordsSearchFilterArray,
        setFilters,
        state
      )}
      {filteredEntitySearchResult.length > 0 ? (
        <div className="pb-4">
          <RecordsTable
            allowEdit={allowEdit}
            loadRemainingRecords={loadRemainingRecords}
            records={filteredEntitySearchResult}
            recordsPerPage={10}
            recordsTableColumns={recordsTableColumns}
            removeColumnIcon={DeleteIcon}
            removeRecord={removeRecordFromParentEntity}
            totalRecords={totalRecords}
            startPage={resultInitialTablePage}
          />
          <Alert
            className="text-center m-0"
            style={AlertStyle.Info}
            text={`Total record count: ${totalRecords}`}
          />
        </div>
      ) : (
        <div className="col-12">
          <p className="text-center">
            {
              "Please change the filtering, then press the submit button"
            }
          </p>
        </div>
      )}

      {recordsToAdd.length > 0 && (
        <>
          <div className="col-12">
            <h5>Records to Add</h5>
          </div>
          <RecordsTable
            allowEdit={true}
            records={recordsToAdd}
            recordsPerPage={5}
            recordsTableColumns={recordsTableColumns}
            removeColumnIcon={MinusIcon}
            removeRecord={removeNewRecordFromAddList}
          />
        </>
      )}

      {recordsToRemove.length > 0 && (
        <>
          <div className="col-12">
            <h5>Records to Remove</h5>
          </div>
          <RecordsTable
            allowEdit={true}
            records={recordsToRemove}
            recordsPerPage={5}
            recordsTableColumns={recordsTableColumns}
            removeColumnIcon={PlusIcon}
            removeRecord={removeRecordsFromRemoveList}
          />
        </>
      )}

      {(recordsToAdd.length > 0 || recordsToRemove.length > 0) && (
        <Alert
          className="text-center m-0"
          style={AlertStyle.Info}
          text={`Updated record count after save: ${
            totalRecords +
            recordsToAdd.length -
            recordsToRemove.length
          }`}
        />
      )}
    </>
  );
}

export default AddRemoveRecordsTable;
