import { SalesPlanType } from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import lookups from "./lookups";
import { ChannelPriceListFormStages } from "./channelPriceList";
import {productAdministratorRoles } from "../js/roles";

export const SalesPlanFormStages = [
  {
    title: "Sales Plan",
    statusEditAllowedRoles: productAdministratorRoles,
    recordEditRoles: productAdministratorRoles,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_salesplantype",
            className: "col-md-6",
            description: "",
            enum: SalesPlanType,
            label: "Type",
            required: true,
            type: "picklist",
          },
          {
            name: "g4b_salesplanid",
            hidden: true,
          },
        ],
      },
      {
        name: "Channel Price Lists",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_channelidname",
              className: "col-short-name",
              label: "Channel",
            },
            {
              name: "g4b_variantpricelistidname",
              className: "col-name",
              label: "Variant Price List",
            },
            {
              name: "g4b_marketinglistidname",
              className: "col-name",
              label: "Marketing List",
            },
            {
              name: "g4b_onsaledate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_onsaledaysbefore",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_onsalehoursbefore",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_offsaledate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_activitystartdate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_activityenddate",
              className: "col-date",
              type: "datetime",
            },
            { name: "g4b_discountpercent", className: "col-number" },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "g4b_channelidname",
              type: "select",
            },
            {
              name: "g4b_variantpricelistidname",
              type: "select",
            },
            {
              name: "g4b_marketinglistidname",
              type: "select",
            },
            {
              name: "g4b_onsaledate",
              type: "datetime",
            },
            {
              name: "g4b_offsaledate",
              type: "datetime",
            },
            {
              name: "g4b_activitystartdate",
              type: "datetime",
            },
            {
              name: "g4b_activityenddate",
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_channelpricelist",
          linkForNewRecord: "/channel-price-list",
          linkedEntityId: "g4b_salesplanid",
          lookup: lookups.g4b_channelpricelist.all,
          subFormStages: ChannelPriceListFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
