import { DispatchMethods } from "./enums";
import { FormProvider } from "./FormContext";
import Form from "./Form";
import { Editor } from "../elements/_Elements";
import lookups from "../../forms/lookups";

function SubForm({
  dispatch,
  entityName,
  errors,
  setSubForm,
  state,
  subForm,
  values,
}) {
  const { field, id } = subForm;
  const { loadStateRelatedEntityName, lookup, subFormStages } = field;
  const linkedEntities = field.linkedEntityId
    ? [{ Key: field.linkedEntityId, Value: subForm.linkedEntityId }]
    : [];
  return (
    <FormProvider>
      <Editor className="p-0">
        <Form
          cancelSubForm={() => {
            // clear the sub form values
            setSubForm("");
          }}
          entityName={lookup.entityName}
          id={id}
          isSubForm={true}
          linkedEntities={linkedEntities}
          onSubmitSubForm={(id, name, submitValues) => {
            if (state.lookupOptions) {
              // clear the cache for the lookup options
              state.lookupOptions
                .filter((l) => l.name === lookup.name)
                .forEach((l) => {
                  sessionStorage.removeItem(l.name);
                });

              // update the lookup options
              const lookupOptions = state.lookupOptions.map((l) =>
                l.name === lookup.name
                  ? {
                      name: l.name,
                      data: [
                        ...l.data.filter((data) => data.Key !== id),
                        { Key: id, Value: name },
                      ].sort((a, b) => {
                        if (a.Value === null && b.Value === null) {
                          return 0;
                        } else if (
                          a.Value === null &&
                          b.Value !== null
                        ) {
                          return -1;
                        } else if (
                          a.Value !== null &&
                          b.Value === null
                        ) {
                          return 1;
                        } else {
                          return a.Value.localeCompare(b.Value);
                        }
                      }),
                    }
                  : l
              );

              dispatch({
                type: DispatchMethods.SetLookupOptions,
                lookupOptions: lookupOptions,
              });
            }
            //Update the related entity records in the state with the new values
            if (loadStateRelatedEntityName) {
              // update the related entity records for the entity in loadStateRelatedEntityName
              var updatedRelatedEntities = state.relatedEntities;
              var updatedRelatedEntity = updatedRelatedEntities.find(
                (r) => r.entityName === loadStateRelatedEntityName
              );
              var highlightRelatedEntities = [];
              const now = new Date();
              submitValues["modifiedon"] = now.toString();
              if (updatedRelatedEntity) {
                var record = updatedRelatedEntity.entities.find(
                  (e) => e.Id === id
                );
                highlightRelatedEntities.push({
                  entityName: loadStateRelatedEntityName,
                  id: id,
                });
                if (record && record.Fields) {
                  //Loop through each field in the submitValues and add or update that
                  //field value on the field records
                  for (const [key, value] of Object.entries(
                    submitValues
                  )) {
                    //If the parentFormEntityName is "contact" and the key is "country"
                    //then we don't want the Guid to be set onto the related entity
                    //but rather the string which should be in countryName.
                    //This is because contact saved the country as a string rather than
                    //the guid so need to make sure we update it accordingly
                    if (
                      entityName === "contact" &&
                      key === "country" &&
                      submitValues["countryname"]
                    ) {
                      record.Fields[key] =
                        submitValues["countryname"];
                    } else {
                      record.Fields[key] = value;
                    }

                    //If the key is one of the name fields like name, g4b_name, g4c_name, etc
                    //then also update the Name on the record accordingly
                    if (
                      key === "name" ||
                      key === "g4_name" ||
                      key === "g4b_name" ||
                      key === "g4c_name" ||
                      key === "g4d_name" ||
                      key === "g4gdpr_name" ||
                      key === "g4m_name" ||
                      key === "listname"
                    ) {
                      record.Name = value;
                    }
                  }
                } else {
                  submitValues["createdon"] = now.toString();
                  submitValues["statuscode"] = 1;
                  submitValues["statecode"] = 0;
                  //If the parentFormEntityName is "contact"
                  //and the we have a key called "countryName",
                  //then set the value for the key of "country"
                  //to have that "countryName" value
                  //as we want the country name string rather than the guid
                  if (
                    entityName === "contact" &&
                    submitValues["countryname"]
                  ) {
                    submitValues["country"] =
                      submitValues["countryname"];
                  }

                  updatedRelatedEntity.entities.push({
                    Id: id,
                    Name: name,
                    Fields: submitValues,
                  });
                }
                dispatch({
                  type: DispatchMethods.SetRelatedEntities,
                  relatedEntities: updatedRelatedEntities,
                  highlightRelatedEntities: highlightRelatedEntities,
                });
              }
            }

            // set error to null when the parent form reloads
            errors[field.name] = null;

            // set the values for when the parent form reloads
            const reloadValues = values;

            // set the field value to the id of the newly created record
            // if the subform type is a checklist, then add this new ID to the array of values
            // do the same for the name property
            const nameField = field.name + "name";

            if (field.type === "checklist") {
              reloadValues[field.name] = reloadValues[field.name]
                ? [...values[field.name], id]
                : [id];

              reloadValues[nameField] = reloadValues[nameField]
                ? [...values[nameField], name]
                : [name];
            } else {
              reloadValues[field.name] = id;
              reloadValues[nameField] = name;
            }

            dispatch({
              type: DispatchMethods.SetReloadValues,
              reloadValues: reloadValues,
            });

            // clear the sub form values
            setSubForm("");
          }}
          parentFormEntityName={lookups[entityName].all.name}
          parentFormFieldLookup={lookup}
          parentFormState={state}
          parentFormValues={values}
          stages={subFormStages}
        />
      </Editor>
    </FormProvider>
  );
}

export default SubForm;
