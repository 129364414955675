import { Button, FormField } from "../elements/_Elements";
import { ButtonStyle } from "../../js/enums";
import {
  checkForValidRoles,
  checkIfFieldDisabled,
  checkIfFieldRequired,
  checkIfUserRoleCanEditRecord,
  displayElement,
  getDescription,
  getEnum,
  getFieldLabel,
  getFieldValue,
  getLookupFieldOptions,
  getTooltipDescription,
  setCreateOrUpdateLookupRecord,
  setCustomActionValues,
} from "./Helpers";
import { useGlobalState } from "../../GlobalContext";
import { getPageAllowedRoles } from "../../js/roles";

function FormFields({
  disabled,
  dispatch,
  entityName,
  errors,
  handleBlur,
  handleChange,
  handleCheckListChange,
  handleFilteredPicklistChange,
  handlePicklistChange,
  overview = false,
  parentFormState,
  section,
  setCustomAction,
  setFieldValue,
  setSubForm,
  stages,
  state,
  touched,
  values,
}) {
  const globalState = useGlobalState();
  const userRoleCanEditRecord = checkIfUserRoleCanEditRecord(
    globalState,
    stages
  );
  return (
    <>
      {section.name && (
        <div className="col-12">
          <h5>{section.name}</h5>
        </div>
      )}
      {section.description && <p>{section.description}</p>}
      {section.fields
        .filter((field) =>
          displayElement(field, state, values, globalState)
        )
        .map((field, i) => {
          const userAllowedToAddOrEdit =
            userRoleCanEditRecord &&
            (!field.pageRolesToCheck ||
              checkForValidRoles(
                getPageAllowedRoles(field.pageRolesToCheck),
                globalState
              ));
          const fieldDisabled = checkIfFieldDisabled(
            field,
            state,
            values
          );
          const allowEdit =
            !fieldDisabled &&
            userAllowedToAddOrEdit &&
            field.allowEdit &&
            values[field.name];
          const allowAdd =
            !fieldDisabled &&
            userAllowedToAddOrEdit &&
            field.allowAdd;
          const hasCustomActions =
            field.customActions &&
            field.customActions.length > 0 &&
            field.customActionLabels &&
            field.customActionLabels.length > 0 &&
            field.customActionLabels.length ===
              field.customActions.length;

          return field.lineBreak ? (
            <div className="w-100" key={i}></div>
          ) : (
            <div
              className={
                overview && field.overviewClassName
                  ? field.overviewClassName
                  : field.className
                  ? field.className
                  : "col-md-4"
              }
              key={i}
            >
              <FormField
                {...{
                  dispatch,
                  entityName,
                  field,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  setSubForm,
                  stages,
                  state,
                  touched,
                }}
                actions={
                  allowEdit || allowAdd || hasCustomActions ? (
                    <>
                      {allowEdit && (
                        <Button
                          className={`${
                            field.allowAdd ? "me-3" : ""
                          } p-0`}
                          disabled={disabled}
                          style={ButtonStyle.Link}
                          text={"Edit"}
                          onClick={() =>
                            setCreateOrUpdateLookupRecord(
                              setSubForm,
                              field,
                              values[field.name],
                              null
                            )
                          }
                          type={"button"}
                        />
                      )}
                      {allowAdd && (
                        <Button
                          className="p-0"
                          disabled={disabled}
                          onClick={() =>
                            setCreateOrUpdateLookupRecord(
                              setSubForm,
                              field,
                              null,
                              null
                            )
                          }
                          style={ButtonStyle.Link}
                          text={"Add"}
                          type={"button"}
                        />
                      )}
                      {hasCustomActions && (
                        <>
                          {field.customActions.map((action, i) => {
                            return (
                              <Button
                                key={i}
                                disabled={disabled}
                                onClick={() =>
                                  setCustomActionValues(
                                    action,
                                    field.customActionLabels[i],
                                    field,
                                    setCustomAction
                                  )
                                }
                                style={ButtonStyle.Link}
                                text={field.customActionLabels[i]}
                                type={"button"}
                              />
                            );
                          })}
                        </>
                      )}
                    </>
                  ) : null
                }
                description={getDescription(
                  field,
                  state,
                  values,
                  parentFormState
                )}
                disabled={
                  disabled || !userAllowedToAddOrEdit || fieldDisabled
                }
                errors={errors[field.name]}
                handleCheckListChange={(event) =>
                  handleCheckListChange(
                    event,
                    handleChange,
                    state,
                    values,
                    field
                  )
                }
                handleDateChange={(date) =>
                  setFieldValue(field.name, date)
                }
                handleFilteredPicklistChange={(event) =>
                  handleFilteredPicklistChange(
                    event,
                    handleChange,
                    field.name,
                    values
                  )
                }
                handlePicklistChange={(event) =>
                  handlePicklistChange(event, handleChange, values)
                }
                label={getFieldLabel(field, state, values)}
                mandatory={checkIfFieldRequired(
                  field,
                  state,
                  values,
                  globalState
                )}
                options={getLookupFieldOptions(
                  field,
                  state,
                  values,
                  parentFormState
                )}
                optionsEnum={getEnum(
                  field,
                  state,
                  values,
                  globalState
                )}
                parentFormState={parentFormState}
                showTimeSelect={
                  field.showTimeSelect ? field.showTimeSelect : false
                }
                showTimeSelectOnly={
                  field.showTimeSelectOnly
                    ? field.showTimeSelectOnly
                    : false
                }
                tooltipDescription={getTooltipDescription(
                  field,
                  state,
                  values
                )}
                value={getFieldValue(field, values)}
                values={values}
              />
            </div>
          );
        })}
    </>
  );
}

export default FormFields;
