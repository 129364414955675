import lookups from "./lookups";

export const PriceBreakFormStages = [
  {
    title: "Price Break",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_pricebreakgroupid",
            className: "col-md-6",
            description: "",
            label: "Price Break Group",
            lookup: lookups.g4b_pricebreakgroup.all,
            required: true,
            type: "lookup",
          },
          {
            name: "g4b_productid",
            className: "col-md-6",
            description: "",
            label: "Product",
            lookup: lookups.g4b_productbase.all,
            required: true,
            type: "lookup",
          },
          {
            name: "g4b_quantity",
            className: "col-md-6",
            description: "",
            label: "Quantity",
            required: true,
            type: "int",
            validate: (value) => {
              const isValid = value >= 0;
              return isValid
                ? ""
                : "You must enter a number greater than 0";
            },
          },
          {
            name: "g4b_discountpercentage",
            className: "col-md-6",
            description: "",
            label: "Discount Percentage",
            required: true,
            type: "decimal",
            validate: (value) => {
              const isValid = value >= 0 && value <= 100;
              return isValid
                ? ""
                : "You must enter a number between 0 and 100";
            },
          },
        ],
      },
    ],
  },
];
