import { isSameOrLaterDate } from "../js/utility";
import lookups from "./lookups";
import { MarketingListFormStages } from "./marketingList";
import { PriceBreakGroupFormStages } from "./priceBreakGroup";
import { VariantPriceListFormStages } from "./variantPriceList";

export const ChannelPriceListFormStages = [
  {
    title: "Channel Price List",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_salesplanid",
            className: "col-md-6",
            description: "The sales plan this price list is for.",
            label: "Sales Plan",
            lookup: lookups.g4b_salesplan.all,
            required: true,
            type: "lookup",
          },
          {
            name: "g4b_channelid",
            className: "col-md-6",
            description: "The channel this price list is for.",
            label: "Channel",
            lookup: lookups.g4b_channel.all,
            required: true,
            type: "lookup",
          },
          {
            name: "g4b_variantpricelistid",
            allowAdd: true,
            allowEdit: true,
            className: "col-md-6",
            description:
              "The variant price list for this price list.",
            label: "Variant Price List",
            lookup: lookups.g4b_variantpricelist.all,
            required: true,
            subFormStages: VariantPriceListFormStages,
            type: "lookup",
          },
        ],
      },
      {
        name: "On Sale Dates",
        fields: [
          {
            name: "g4b_onsaledate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy HH:mm",
            description:
              "The date and time this price list goes on sale.",
            label: "On Sale Date",
            showTimeSelect: true,
            type: "datetime",
          },
          {
            name: "g4b_offsaledate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy HH:mm",
            description:
              "The date and time this price list goes off sale.",
            filterDate: (date, values) => {
              return values.g4b_onsaledate
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4b_onsaledate)
                  )
                : true;
            },
            label: "Off Sale Date",
            showTimeSelect: true,
            type: "datetime",
            validate: (date, values) => {
              const isValid = values.g4b_onsaledate
                ? isSameOrLaterDate(date, values.g4b_onsaledate)
                : true;
              return isValid ? "" : "Select a later date";
            },
          },
        ],
      },
      {
        name: "Fixtures and Series",
        description:
          "These settings apply to fixtures and series only.",
        fields: [
          {
            name: "g4b_onsaledaysbefore",
            className: "col-md-6",
            description:
              "The price list will go on sale this many days before the fixture or series start date. If not set, then the default value is 0.",
            label: "On Sale Days Before",
            type: "int",
          },
          {
            name: "g4b_onsalehoursbefore",
            className: "col-md-6",
            description:
              "The price list will go on sale this many hours before the fixture or series start date. If not set, then the default value is 0.",
            label: "On Sale Hours Before",
            type: "int",
          },
          {
            name: "g4b_offsaledaysbefore",
            className: "col-md-6",
            description:
              "The price list will go off sale this many days before the fixture or series start date. If not set, then the default value is 0.",
            label: "Off Sale Days Before",
            type: "int",
          },
          {
            name: "g4b_offsalehoursbefore",
            className: "col-md-6",
            description:
              "The price list will go off sale this many hours before the fixture or series start date. If not set, then the default value is 0.",
            label: "Off Sale Hours Before",
            type: "int",
          },
        ],
      },
      {
        name: "Activity Period",
        fields: [
          {
            name: "g4b_activitystartdate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy HH:mm",
            description:
              "The price list will only be active for activities starting after this date. If not set, then there is no restriction.",
            label: "Activity Start Date",
            showTimeSelect: true,
            type: "datetime",
          },
          {
            name: "g4b_activityenddate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy HH:mm",
            description:
              "The price list will only be active for activities starting before this date. If not set, then there is no restriction.",
            filterDate: (date, values) => {
              return values.g4b_activitystartdate
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4b_activitystartdate)
                  )
                : true;
            },
            label: "Activity End Date",
            showTimeSelect: true,
            type: "datetime",
            validate: (date, values) => {
              const isValid = values.g4b_activitystartdate
                ? isSameOrLaterDate(
                    date,
                    values.g4b_activitystartdate
                  )
                : true;
              return isValid ? "" : "Select a later date";
            },
          },
        ],
      },
      {
        name: "Entitlements",
        fields: [
          {
            name: "g4b_marketinglistid",
            allowAdd: true,
            allowEdit: true,
            className: "col-md-6",
            description:
              "Only contacts in this marketing list can purchase from this price list.",
            label: "Marketing List",
            lookup: lookups.list.all,
            subFormStages: MarketingListFormStages,
            type: "lookup",
          },
          {
            name: "g4b_quantity",
            className: "col-md-6",
            description:
              "The maximum quantity that can be purchased from this price list, taking into account the associated product and contact.",
            label: "Max Quantity",
            type: "int",
          },
        ],
      },
      {
        name: "Discounts",
        fields: [
          {
            name: "g4b_discountpercent",
            description: "The discount percent for this price list.",
            label: "Discount Percent",
            type: "decimal",
          },
          {
            name: "g4b_pricebreakgroupid",
            allowAdd: true,
            allowEdit: true,
            description: "The price break group for this price list.",
            label: "Price Break Group",
            lookup: lookups.g4b_pricebreakgroup.all,
            subFormStages: PriceBreakGroupFormStages,
            type: "lookup",
          },
        ],
      },
    ],
  },
];
