import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeft } from "../Icons";
import {
  Button,
  JumpToContent,
  ProgressTracker,
  SetStatusModal,
} from "./_Elements";
import {
  ButtonStyle,
  LoadingState,
  RecordStatus,
} from "../../js/enums";
import "./FormPageHeader.scss";
import { SetRecordState } from "../../js/service";
import {
  useGlobalReducer,
  useGlobalState,
} from "../../GlobalContext";
import { checkForValidRoles, handleRedirect } from "../form/Helpers";
import { administratorRoles } from "../../js/roles";
import AnonymiseContactModal from "../form/contact/anonymiseContactModal";
import MergeContactsModal from "../form/contact/mergeContactsModal";
import classNames from "classnames";

function ContactFormPageHeader({
  allowShowInformation,
  dispatch,
  entityName,
  filteredStages,
  header,
  onBack,
  scrollToElement,
  setLoading,
  setShowInformation,
  showInformation,
  stages,
  state,
}) {
  const [
    showConfirmStatusChangeModal,
    setShowConfirmStatusChangeModal,
  ] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const globalDispatch = useGlobalReducer();
  const globalState = useGlobalState();

  const existingRecord = state && state.id;

  const allowedAnonymiseAndMerge = checkForValidRoles(
    administratorRoles,
    globalState
  );

  const [displayAnonymiseModal, setDisplayAnonymiseModal] =
    useState(false);
  const [displayMergeModal, setDisplayMergeModal] = useState(false);

  const handleRecordStatusChange = () => {
    const setStatus = async () => {
      setLoading(LoadingState.Loading);
      try {
        const [serviceResponse] = await Promise.all([
          SetRecordState(globalDispatch, {
            Ids: [state.id],
            EntityName: entityName,
            StateCode: state.statecode === 0 ? 1 : 0,
          }),
        ]);
        if (serviceResponse && serviceResponse.status === 200) {
          handleRedirect(null, dispatch, state.id, "refreshPage");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(LoadingState.Loaded);
      }
    };
    setStatus();
  };

  const displayJumpTo =
    stages && stages.length > 0 && stages.some((s) => s.id);

  return (
    <>
      {showConfirmStatusChangeModal && (
        <SetStatusModal
          handleRecordStatusChange={handleRecordStatusChange}
          recordStatusToShow={
            state.statecode === 0
              ? RecordStatus.Active
              : RecordStatus.Inactive
          }
          setShowConfirmStatusChangeModal={
            setShowConfirmStatusChangeModal
          }
          singleRecord={true}
          state={state}
        />
      )}
      {displayAnonymiseModal && (
        <AnonymiseContactModal
          setDisplayAnonymiseModal={setDisplayAnonymiseModal}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          state={state}
        />
      )}
      {displayMergeModal && (
        <MergeContactsModal
          setDisplayMergeModal={setDisplayMergeModal}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          state={state}
        />
      )}
      <div id="form-page-header" className="sticky-top px-3">
        {state &&
          !state.id &&
          filteredStages &&
          filteredStages.length > 1 && (
            <ProgressTracker {...{ filteredStages, state }} />
          )}
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {state && header && !showInformation && onBack && (
              <Button
                className="btn-sm me-3"
                onClick={onBack}
                style={ButtonStyle.Info}
                text={<FontAwesomeIcon icon={ArrowLeft} />}
              />
            )}
            {header && (
              <h2 className="fw-bold text-dark me-3">{header}</h2>
            )}
            {state &&
              state.id &&
              (state.statecode === 0 || state.statecode === 1) && (
                <>
                  <div className="dropdown">
                    <button
                      className={classNames(
                        "btn",
                        state.statecode === 0
                          ? "btn-success"
                          : "btn-warning"
                      )}
                      type="button"
                      id="status"
                      aria-expanded="false"
                      disabled={true}
                    >
                      {state.statecode === 0 ? "Active" : "Inactive"}
                    </button>
                  </div>
                </>
              )}
          </div>
          <div className="d-flex align-items-center">
            <div className="me-2 d-flex align-items-end">
              {allowShowInformation && showInformation ? (
                <Button
                  onClick={() => setShowInformation(false)}
                  text="Content"
                />
              ) : (
                <>
                  {existingRecord && allowedAnonymiseAndMerge && (
                    <div className="dropdown">
                      <button
                        className={classNames(
                          "btn",
                          "btn-info",
                          "dropdown-toggle",
                          "me-2"
                        )}
                        type="button"
                        id="optionsDropDown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        disabled={isSubmitting}
                      >
                        More Actions
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="optionsDropDown"
                      >
                        <li
                          key={"anonymiseContact"}
                          onClick={() => {
                            setDisplayAnonymiseModal(true);
                          }}
                        >
                          <span className="dropdown-item cursor-pointer">
                            {"Anonymise Contact"}
                          </span>
                        </li>
                        <li
                          key={"mergeContacts"}
                          onClick={() => {
                            setDisplayMergeModal(true);
                          }}
                        >
                          <span className="dropdown-item cursor-pointer">
                            {"Merge Contacts"}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                  {displayJumpTo && (
                    <JumpToContent {...{ scrollToElement, stages }} />
                  )}
                </>
              )}
            </div>
            {allowShowInformation && !showInformation && (
              <Button
                onClick={() => setShowInformation(true)}
                style={ButtonStyle.OutlinePrimary}
                text="Info"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactFormPageHeader;
