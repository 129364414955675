import lookups from "./lookups";
import { AccessControlProviderFormStages } from "./accessControlProvider";
import {
  DefaultPrintOptions,
  PriceListMode,
  TicketReprintBehaviour,
} from "../js/enums";
import { CharityFormStages } from "./charity";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { SalesPlanFormStages } from "./salesPlan";

export const GlobalSettingsFormStages = [
  {
    title: "Options",
    id: "options",
    sections: [
      {
        name: "General",
        fields: [
          {
            name: "g4b_name",
            isNameField: true,
            hidden: true,
          },
          {
            name: "g4b_useoperatorgroups",
            className: "col-md-4",
            label: "Use Operator Groups",
            type: "bit",
          },
          {
            name: "g4b_usecoupons",
            className: "col-md-4",
            label: "Use coupons",
            type: "bit",
          },
          {
            name: "g4b_usevouchers",
            className: "col-md-4",
            label: "Use Vouchers",
            type: "bit",
          },
          {
            name: "g4b_generatecontactextreference",
            className: "col-md-4",
            label: "Generate Contact Ext Reference",
            type: "bit",
          },
          {
            name: "g4b_membershipnumberautogenerated",
            className: "col-md-4",
            label: "Membership Number Auto Generated",
            type: "bit",
          },
          {
            name: "g4b_accountextreferencerequired",
            className: "col-md-4",
            label: "Account External Reference Required",
            type: "bit",
          },
          {
            name: "g4b_createtickets",
            className: "col-md-4",
            label: "Create Tickets",
            type: "bit",
          },
          {
            name: "g4b_oneticketlimitperfixture",
            className: "col-md-4",
            label: "One Ticket Limit Per Fixture",
            type: "bit",
          },
          {
            name: "g4b_optionsdisplayfindseatstab",
            className: "col-md-4",
            label: "Display Find Seats Tab",
            type: "bit",
          },
          {
            name: "g4b_bookingdetailswindowrenewmembership",
            className: "col-md-4",
            label: "Booking Details Window Renew Membership",
            type: "bit",
          },
          {
            name: "g4b_contactmobileisrequired",
            className: "col-md-4",
            label: "Contact Mobile Is Required",
            type: "bit",
          },
          {
            name: "g4b_allowadditionalpaymentsafterfinance",
            className: "col-md-4",
            label: "Allow Additional Payments After Finance",
            type: "bit",
          },
          {
            name: "g4b_includeconnectionspurchasingentitlement",
            className: "col-md-4",
            label: "Include Connections Purchasing Entitlement",
            type: "bit",
          },
          {
            name: "g4b_enabledonationsbybundleitem",
            className: "col-md-4",
            description:
              "If enabled, donations will be created for each product bundle item",
            label: "Enable Donations By Bundle Item",
            type: "bit",
          },
          {
            name: "g4b_optionsdefaultprintoption",
            className: "col-md-4",
            label: "Default Print Options",
            type: "picklist",
            enum: DefaultPrintOptions,
          },
          {
            name: "g4b_ticketreprintbehaviour",
            className: "col-md-4",
            label: "Ticket Reprint Behaviour",
            type: "picklist",
            enum: TicketReprintBehaviour,
          },
          {
            name: "g4b_currentsalesplanidname",
            hidden:true,
          },
          {
            name: "g4b_currentsalesplanid",
            allowAdd: false,
            description: "",
            className: "col-md-4",
            label: "Current Sales Plan",
            lookup: lookups.g4b_salesplan.other,
            subFormStages: SalesPlanFormStages,
            type: "lookup",
          },
          {
            name: "g4b_pricelistmode",
            className: "col-md-4",
            description: "Price list mode",
            label: "Price list mode",
            type: "picklist",
            enum: PriceListMode,
            hidden: true,
          },
        ],
      },
      {
        name: "Shopping Cart Panel Options",
        fields: [
          {
            name: "g4b_optionsscpdisplaycommentpanel",
            className: "col-md-4",
            label: "Display Comment Panel",
            type: "bit",
          },
          {
            name: "g4b_optionsscpdisplayproductusersbutton",
            className: "col-md-4",
            label: "Display Product Users Button",
            type: "bit",
          },
          {
            name: "g4b_optionsscpdisplayprintwizardbutton",
            className: "col-md-4",
            label: "Display Print Wizard Button",
            type: "bit",
          },
          {
            name: "g4b_scpdisableprintbuttonsafterprintsuccess",
            className: "col-md-4",
            label: "Disable Print Buttons After Print Success",
            type: "bit",
          },
          {
            name: "g4b_optionsscpdisplayseatsincartitem",
            className: "col-md-4",
            label: "Display Seats In Cart Item",
            type: "bit",
          },
          {
            name: "g4b_optionsshowupgradebutton ",
            className: "col-md-4",
            label: "Show Upgrade Button",
            type: "bit",
          },
          {
            name: "g4b_scpenableprintbuttonafterfullpayment  ",
            className: "col-md-4",
            label: "Enable Print Button After Full Payment",
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Access Control",
    id: "access-control",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_accesscontrolproviderid",
            allowAdd: true,
            allowEdit: true,
            className: "col-md-6",
            description: "",
            label: "Provider",
            lookup: lookups.g4b_accesscontrolprovider.all,
            subFormStages: AccessControlProviderFormStages,
            type: "lookup",
          },
          {
            name: "g4b_antipassbackperiod",
            className: "col-md-6",
            description:
              "Time period to reject scans after a successful scan, in minutes",
            label: "Anti pass back period",
            type: "int",
          },
          {
            name: "g4b_accessvalidationsuccessperiod",
            className: "col-md-6",
            description: "Provide a time period in ms",
            label: "Access Validation Success Period",
            type: "int",
          },
          {
            name: "g4b_accessvalidationfailedperiod",
            className: "col-md-6",
            description: "Provide a time period in ms",
            label: "Access Validation Failed Period",
            type: "int",
          },
        ],
      },
    ],
  },
  {
    title: "Cross Sell Products",
    id: "cross-sell-products",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_crosssellpageheader",
            className: "col-md-6",
            label: "Cross Sell Page Header",
            description: "",
            type: "nvarchar",
          },
          {
            name: "g4b_crosssellpagesubheader",
            className: "col-md-6",
            label: "Cross Sell Page Sub Header",
            description:
              "To display sub header/title message on Cross Sell Page",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Charities",
    id: "charities",
    editOnOverview: true,
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4d_gift_aid_percent",
              className: "col-number",
            },
            {
              name: "g4d_charity_number",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4d_declaration_future_years",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4d_declaration_historical_years",
              className: "col-number",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name", "g4d_charity_number"],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/charity",
          lookup: lookups.g4d_charity.all,
          subFormStages: CharityFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
