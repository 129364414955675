import classNames from "classnames";
import { useGlobalState } from "../../GlobalContext";
import "./style.scss";

function Header() {
  const globalState = useGlobalState();
  const { slot, user, version } = globalState;

  return (
    <nav
      id="header"
      className={classNames(
        "navbar sticky-top p-0 d-flex justify-content-between",
        slot ? `${slot.toLowerCase()}-slot` : ""
      )}
    >
      <ul className="nav">
        {version && (
          <li className="nav-item d-flex align-items-center">
            <span className="px-3">Version: {version}</span>
          </li>
        )}
      </ul>
      {user && (
        <ul className="nav">
          {user.userDetails && (
            <li className="nav-item d-flex align-items-center">
              <span className="px-3">{user.userDetails}</span>
            </li>
          )}
          <li className="nav-item text-nowrap">
            <a href="/.auth/logout" className="nav-link px-3">
              Sign out
            </a>
          </li>
        </ul>
      )}
    </nav>
  );
}

export default Header;
