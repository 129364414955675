import Blacklist from "../components/form/communication/Blacklist";
import MailExclusion from "../components/form/communication/MailExclusion";

export const BlacklistFormStages = [
  {
    title: "Blacklist & Mail Exclusion",
    sections: [
      {
        name: "Blacklist",
        description:
          "The blacklist is a list of email addresses that have been deemed to be permanently unreachable due to receiving hard bounces from the destination mail server or DNS.",
        fields: [
          {
            name: "",
            className: "col-12",
            component: Blacklist,
            type: "component",
          },
        ],
      },
      {
        name: "Mail Exclusion",
        description:
          "The mail exclusion is a list of email addresses to which mail cannot be sent. These usually come from feedback loop notifications, where the recipient has reported the email as spam.",
        fields: [
          {
            name: "",
            className: "col-12",
            component: MailExclusion,
            type: "component",
          },
        ],
      },
    ],
    showButtonSection: () => false,
  },
];
