import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import {
  useGlobalReducer,
  useGlobalState,
} from "../../GlobalContext";
import { useFormReducer, useFormState } from "../form/FormContext";
import { Loading } from "../elements/_Elements";
import FormSection from "./FormSection";
import Overview from "./Overview";
import {
  filterStages,
  getStagesToRender,
  useExistingRecord,
} from "./Helpers";
import { DispatchMethods } from "./enums";

function Form({
  auditLabel,
  cancelSubForm,
  closeRedirect,
  customCopyComponent,
  entityName,
  headerComponent,
  hideFormWhileSubmitting,
  id = new URLSearchParams(window.location.search).get("id"),
  informationComponent,
  isSubForm = false,
  linkedEntities,
  loadDataMethod,
  methodName,
  onSubmitSubForm,
  parentFormEntityName,
  parentFormFieldLookup,
  parentFormState,
  parentFormValues,
  refreshPageUponSave,
  saveAndCloseRedirect,
  saveAndNewRedirect,
  showCopyButton,
  showInfo,
  showSaveAndCloseButton,
  showSaveButton,
  showSaveAndNewButton,
  stages,
}) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();
  const state = useFormState();
  const globalState = useGlobalState();

  // if an id is given, load the existing record
  useExistingRecord(
    id,
    entityName,
    globalDispatch,
    dispatch,
    loadDataMethod,
    state,
    stages
  );

  // clear the redirect if it matches the current path
  useEffect(() => {
    if (
      state.redirect ===
      window.location.pathname + window.location.search
    ) {
      dispatch({ type: DispatchMethods.SetRedirect, redirect: "" });
    }
  }, [dispatch, state.redirect]);

  if (state.redirect) {
    return <Navigate to={state.redirect} replace={state.replace} />;
  }

  if (state.initialLoad) {
    return <Loading />;
  }

  //Filter the stages based on whether they should be shown based a displayIf condition if one is given
  let filteredStages = filterStages(stages, state, globalState);
  const stagesToRender = getStagesToRender(filteredStages);
  return (
    <>
      {state.stage <= stagesToRender.length ? (
        <FormSection
          {...{
            auditLabel,
            cancelSubForm,
            closeRedirect,
            customCopyComponent,
            dispatch,
            entityName,
            filteredStages,
            globalDispatch,
            globalState,
            headerComponent,
            informationComponent,
            isSubForm,
            linkedEntities,
            methodName,
            onSubmitSubForm,
            parentFormEntityName,
            parentFormFieldLookup,
            parentFormState,
            parentFormValues,
            refreshPageUponSave,
            showCopyButton,
            showInfo,
            showSaveAndCloseButton,
            showSaveAndNewButton,
            state,
            saveAndNewRedirect,
          }}
          stages={stagesToRender}
          stageIndex={state.stage}
        />
      ) : state.stage >= stagesToRender.length + 1 &&
        stagesToRender.length > 1 ? (
        <Overview
          {...{
            auditLabel,
            cancelSubForm,
            closeRedirect,
            customCopyComponent,
            dispatch,
            entityName,
            globalDispatch,
            globalState,
            headerComponent,
            hideFormWhileSubmitting,
            informationComponent,
            isSubForm,
            linkedEntities,
            methodName,
            onSubmitSubForm,
            parentFormEntityName,
            parentFormFieldLookup,
            parentFormState,
            parentFormValues,
            refreshPageUponSave,
            saveAndCloseRedirect,
            saveAndNewRedirect,
            showCopyButton,
            showInfo,
            showSaveAndCloseButton,
            showSaveAndNewButton,
            showSaveButton,
            state,
          }}
          stages={filteredStages}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Form;
