import { useEffect, useState } from "react";
import { useGlobalReducer } from "../../../GlobalContext";
import { LoadingState } from "../../../js/enums";
import { GetBlacklistEntityAudits } from "../../../js/service";
import { Loading, Pagination } from "../../elements/_Elements";
import { displayLocalDate } from "../../../js/utility";

function BlacklistAudits() {
  const globalDispatch = useGlobalReducer();

  const [auditState, setAuditState] = useState({
    loading: LoadingState.NotLoaded,
    audits: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRecords, setCurrentRecords] = useState([]);

  const { audits, loading } = auditState;
  const recordsPerPage = 10;

  useEffect(() => {
    const getEntityAudits = async () => {
      try {
        const [serviceResponse] = await Promise.all([
          GetBlacklistEntityAudits(globalDispatch),
        ]);
        if (serviceResponse && serviceResponse.data) {
          setAuditState({
            loading: LoadingState.Loaded,
            audits: serviceResponse.data,
          });
        }
      } catch (error) {
        console.error("Error fetching initial state:", error);
      }
    };

    if (loading !== LoadingState.Loaded) {
      getEntityAudits();
    }
  }, [globalDispatch, loading]);

  return (
    <div className="row">
      <div className="col-12">
        {loading !== LoadingState.Loaded ? (
          <Loading />
        ) : (
          <>
            <div className="list-view">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr className="table-info">
                      <th scope="col" className="col-name">
                        Email address
                      </th>
                      <th scope="col" className="col-short-name">
                        Type
                      </th>
                      <th scope="col" className="col-name">
                        Modified By
                      </th>
                      <th scope="col" className="col-date">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords.map((audit, i) => {
                      return (
                        <tr key={i}>
                          <td>{audit.Label}</td>
                          <td>
                            {audit.Type === 3
                              ? "Unsuppress"
                              : "Mail exclusion removal"}
                          </td>
                          <td>{audit.EmailAddress}</td>
                          <td>{displayLocalDate(audit.CreatedOn)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-end py-3">
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                records={audits}
                recordsPerPage={recordsPerPage}
                setRecords={setCurrentRecords}
                totalPages={
                  audits.length
                    ? Math.ceil(audits.length / recordsPerPage)
                    : null
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default BlacklistAudits;
