import React, { useState } from "react";
import { ButtonStyle } from "../../../js/enums";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import { Update } from "../../../js/service";
import {
  Button,
  Loading,
  Modal,
  SelectWithFiltering,
} from "../../elements/_Elements";
import { handleRedirect, searchRecords } from "../Helpers";
import { getSearchTableFilters } from "../../elements/AddRemoveHelper";
import {
  contactSearchFilterArray,
  getDefaultSelectedAttributes,
} from "./Helpers";
import MergeContactsFieldSelectionTable from "./mergeContactsFieldSelectionTable";

function MergeContactsModal({
  setDisplayMergeModal,
  isSubmitting,
  setIsSubmitting,
  state,
}) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedChildContact, setSelectedChildContact] =
    useState(null);
  const [childContactSearchResult, setChildContactSearchResult] =
    useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState(
    getDefaultSelectedAttributes(state)
  );

  const handleSubmitFilter = () => {
    let filterObject = {};
    let filterArray = [];
    setLoading(true);
    setSelectedChildContact(null);
    contactSearchFilterArray.forEach((filter) => {
      let key = filters.filter((f) => f.Key === filter.name);
      let value = "";
      if (key.length === 1) {
        value = key[0].Value;
      }
      filterArray.push({
        Key: filter.name,
        Value: value,
      });
      filterObject[filter.name] = value;
    });

    filterObject["parentId"] = state.id;
    filterObject["searchfilter"] = "filteroutmaster";
    setFilters(filterArray);
    searchRecords(
      "contact",
      filterObject,
      globalDispatch,
      setChildContactSearchResult,
      setChildContactSearchResult,
      setLoading,
      setIsSubmitting
    );
  };

  const merge = async () => {
    setIsSubmitting(true);
    try {
      let attributes = selectedAttributes;
      attributes.childcontactid = selectedChildContact.Id;
      const successMessage = "Contact merged";
      const [serviceResponse] = await Promise.all([
        Update(
          globalDispatch,
          successMessage,
          {
            Id: state.id,
            Name: state.name,
            Attributes: attributes,
          },
          "contact",
          "ticketing/mergecontacts"
        ),
      ]);
      if (serviceResponse && serviceResponse.data) {
        handleRedirect(null, dispatch, state.id, "refreshPage");
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={"Merge Contacts"}
      modalCloseButtonClick={() => setDisplayMergeModal(false)}
      className="modal modal-dialog-scrollable modal-fullscreen"
    >
      <div className="modal-body">
        <small>
          Select a contact to be merged into{" "}
          <strong>{state.name}</strong>
        </small>

        {loading && <Loading />}
        {!loading && (
          <>
            {getSearchTableFilters(
              filters,
              handleSubmitFilter,
              loading,
              contactSearchFilterArray,
              setFilters,
              state
            )}
            {childContactSearchResult &&
              childContactSearchResult.length > 0 && (
                <div className="col-md-6">
                  <SelectWithFiltering
                    mandatory={false}
                    name={"selectChildContact"}
                    options={childContactSearchResult.map((c) => ({
                      Key: c.Id,
                      Value: `${c.Name} (${c.Fields["emailaddress1"]})`,
                    }))}
                    optionGroups={[]}
                    onChange={(event) => {
                      setSelectedChildContact(
                        childContactSearchResult.find(
                          (c) => c.Id === event.value
                        )
                      );
                      setSelectedAttributes(
                        getDefaultSelectedAttributes(state)
                      );
                    }}
                    placeholder={"Please select"}
                    value={
                      selectedChildContact
                        ? selectedChildContact.Id
                        : null
                    }
                  />
                </div>
              )}
          </>
        )}
        {selectedChildContact && (
          <MergeContactsFieldSelectionTable
            selectedAttributes={selectedAttributes}
            selectedChildContact={selectedChildContact}
            setSelectedAttributes={setSelectedAttributes}
            state={state}
          />
        )}
      </div>
      <div className="modal-footer">
        <Button
          text={"Confirm"}
          style={ButtonStyle.Primary}
          onClick={() => merge()}
          showSpinner={isSubmitting}
          disabled={!selectedChildContact || isSubmitting}
        />
        <Button
          text={"Cancel"}
          style={ButtonStyle.Info}
          onClick={() => setDisplayMergeModal(false)}
          showSpinner={isSubmitting}
          disabled={isSubmitting}
        />
      </div>
    </Modal>
  );
}

export default MergeContactsModal;
