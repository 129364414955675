import lookups from "./lookups";
import {
  checkIfSellFixturesAndSeriesSettingOn,
  getLookupData,
  isSameOrLaterDate,
  priceRegex,
} from "../js/utility";
import { BookableResourceFormStages } from "./bookableResource";
import { CouponTypeFormStages } from "./couponType";
import { FundFormStages } from "./fund";
import { GroupFormStages } from "./group";
import { ProductCalendarFormStages } from "./productCalendar";
import { ProductCategoryFormStages } from "./productCategory";
import { PromotionProductGroupFormStages } from "./promotionProductGroup";
import { ReportingCategoryFormStages } from "./reportingCategory";
import { TaxCodeFormStages } from "./taxCode";
import { VariantTypeCategoryFormStages } from "./variantTypeCategory";
import { VariantTypeFormStages } from "./variantType";
import { WidgetGroupFormStages } from "./widgetGroup";
import { ZoneFormStages } from "./zone";
import ProductVariants from "../components/form/product/ProductVariants";
import {
  BeneficiaryRequirement,
  BeneficiaryRequirementMembership,
  DelayDurationUnit,
  OverviewColumn,
  PassUsageType,
  PriceType,
  ProductModifierType,
  ProductType,
  ProductTypeNoFixturesAndSeries,
  TimeInterval,
} from "../js/enums";
import { SerieFormStages } from "./serie";
import { ProductModifierFormStages } from "./productModifier";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { ProductBundleFormStages } from "./productBundle";
import { CrossSellProductFormStages } from "./crossSellProduct";

export const ProductFormStages = [
  {
    title: "Product type",
    id: "product-type",
    overviewColumn: OverviewColumn.Side,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_type",
            className: "col-md-6",
            description: "",
            displayAltEnumViaGlobalState: (globalState) => {
              if (
                checkIfSellFixturesAndSeriesSettingOn(globalState)
              ) {
                return ProductType;
              } else {
                return ProductTypeNoFixturesAndSeries;
              }
            },
            label: "Type",
            required: true,
            type: "picklist",
          },
        ],
      },
    ],
  },
  {
    title: "General fields",
    id: "general-fields",
    overviewColumn: OverviewColumn.Main,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-8",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_code",
            description: "",
            label: "Code",
            type: "nvarchar",
          },
          {
            name: "primaryImage",
            className: "col-12",
            description: "",
            type: "image",
          },
          {
            name: "g4b_capacity",
            description: "",
            displayIfFromState: (values) =>
              [
                String(ProductType.AutoScheduled),
                String(ProductType.Scheduled),
                String(ProductType.Bundle),
              ].includes(String(values.g4b_type)),
            label: "Capacity",
            required: true,
            type: "int",
          },
          {
            name: "g4b_vouchervalidfordays",
            description: "",
            displayIfFromState: (values) =>
              String(values.g4b_type) === String(ProductType.Voucher),
            label: "Voucher valid days",
            required: true,
            type: "int",
          },
          {
            allowAdd: true,
            allowEdit: true,
            pageRolesToCheck: "coupon-type",
            name: "g4b_couponproducttypeid",
            description: "",
            displayIfFromState: (values) =>
              String(values.g4b_type) === String(ProductType.Coupon),
            label: "Coupon type",
            lookup: lookups.g4b_coupontype.all,
            required: true,
            subFormStages: CouponTypeFormStages,
            type: "lookup",
          },
          {
            name: "g4b_taxcodeid",
            description: "",
            label: "Tax code",
            lookup: lookups.g4b_taxcode.all,
            subFormStages: TaxCodeFormStages,
            type: "lookup",
          },
          {
            name: "g4b_sequence",
            description: "",
            label: "Sequence",
            type: "int",
          },
          {
            name: "g4b_description",
            className: "col-12",
            description: "",
            label: "Description",
            type: "nvarchar",
          },
          {
            name: "g4b_unitsperperson",
            description: "",
            label: "Units per person",
            type: "int",
          },
          {
            name: "g4b_collectvehicleregistration",
            description: "",
            label: "Collect vehicle registration",
            type: "bit",
          },
          {
            allowAdd: true,
            allowEdit: true,
            pageRolesToCheck: "series",
            name: "g4b_seriesid",
            description: "",
            displayIfFromState: (values) =>
              [
                String(ProductType.Fixture),
                String(ProductType.Series),
              ].includes(String(values.g4b_type)),
            label: "Series",
            lookup: lookups.g4b_series.all,
            required: true,
            subFormStages: SerieFormStages,
            type: "lookup",
          },
          {
            allowAdd: true,
            allowEdit: true,
            name: "g4b_productbundleid",
            description: "",
            displayIfFromState: (values) =>
              [String(ProductType.Bundle)].includes(
                String(values.g4b_type)
              ),
            label: "Product bundle",
            lookup: lookups.g4b_productbundle.all,
            required: true,
            subFormStages: ProductBundleFormStages,
            type: "lookup",
          },
          {
            name: "g4b_bookableresourceid",
            allowAdd: true,
            allowEdit: true,
            pageRolesToCheck: "bookable-resource",
            description: "",
            displayIfFromState: (values) =>
              [
                String(ProductType.AutoScheduled),
                String(ProductType.Scheduled),
                String(ProductType.Fixture),
                String(ProductType.Series),
              ].includes(String(values.g4b_type)),
            label: "Bookable resource",
            lookup: lookups.g4b_bookableresource.all,
            required: true,
            subFormStages: BookableResourceFormStages,
            type: "lookup",
          },
          {
            allowAdd: true,
            allowEdit: true,
            pageRolesToCheck: "fund",
            name: "g4b_fund_id",
            description: "",
            label: "Fund",
            lookup: lookups.g4d_fund.all,
            subFormStages: FundFormStages,
            type: "lookup",
          },
          {
            name: "g4b_donationpercentage",
            default: 100,
            description:
              "The percentage of the product price that is for donation.",
            displayIfFromValues: (values) => values.g4b_fund_id,
            label: "Donation percentage",
            required: true,
            type: "decimal",
            validate: (value) => {
              const isValid = value >= 0 && value <= 100;
              return isValid
                ? ""
                : "You must enter a number between 0 and 100";
            },
          },
        ],
      },
      {
        name: "Beneficiaries",
        fields: [
          {
            autoFillValue: (values) =>
              String(values.g4b_type) ===
              String(ProductType.Membership)
                ? BeneficiaryRequirementMembership.Required
                : "",
            name: "g4b_beneficiaryrequirement",
            description: "",
            displayAltEnum: (values) =>
              String(values.g4b_type) ===
              String(ProductType.Membership)
                ? BeneficiaryRequirementMembership
                : BeneficiaryRequirement,
            displayIfFromState: (values) =>
              [
                String(ProductType.AutoScheduled),
                String(ProductType.Fixture),
                String(ProductType.Membership),
                String(ProductType.Pass),
                String(ProductType.Scheduled),
                String(ProductType.Series),
              ].includes(String(values.g4b_type)),
            label: "Beneficiary requirement",
            required: true,
            type: "picklist",
          },
          {
            name: "g4b_skiprequestedbeneficiarycollection",
            description:
              "Allows the operator to skip the assignment of beneficiaries, depending on the terminal configuration",
            displayIfFromValues: (values) =>
              String(values.g4b_beneficiaryrequirement) ===
              String(BeneficiaryRequirement.Requested),
            label: "Allow skip",
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Date and times",
    id: "date-and-times",
    overviewColumn: OverviewColumn.Side,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_availabledatefrom",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description:
              "The earliest date that the product will appear to be on sale",
            label: "Available to sell from",
            type: "datetime",
          },
          {
            name: "g4b_availabledateto",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description:
              "The latest date that the product will appear to be on sale",
            filterDate: (date, values) => {
              return values.g4b_availabledatefrom
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4b_availabledatefrom)
                  )
                : true;
            },
            label: "Available to sell to",
            type: "datetime",
            validate: (date, values) => {
              const isValid = values.g4b_availabledatefrom
                ? isSameOrLaterDate(
                    date,
                    values.g4b_availabledatefrom
                  )
                : true;
              return isValid ? "" : "Select a later date";
            },
          },
          {
            name: "g4b_usagetype",
            description: "",
            displayIfFromState: (values) =>
              String(values.g4b_type) === String(ProductType.Pass),
            label: "Usage type",
            type: "picklist",
            enum: PassUsageType,
          },
          {
            name: "g4b_numberofdaysbeforeentryallowed",
            description:
              "Number of days after purchase that entry is allowed",
            displayIfFromState: (values) =>
              String(values.g4b_type) === String(ProductType.Pass) &&
              values.g4b_usagetype !== null,
            label: "Number of days before entry allowed",
            type: "int",
          },
          {
            name: "g4b_numberofdaysbeforeentryrejected",
            description:
              "The number of days from the purchase date a product can be used for before entry is rejected",
            displayIfFromState: (values) =>
              String(values.g4b_type) === String(ProductType.Pass) &&
              values.g4b_usagetype !== null,
            label: "Number of days before entry rejected",
            type: "int",
          },
          {
            name: "g4b_numberofuses",
            description:
              "Number of entries this allows the product user to a venue",
            displayIfFromState: (values) =>
              String(values.g4b_type) === String(ProductType.Pass) &&
              values.g4b_usagetype !== null,
            label: "Number of uses",
            type: "int",
          },
          {
            name: "g4b_duration",
            description: "",
            displayIfFromState: (values) =>
              [
                String(ProductType.AutoScheduled),
                String(ProductType.Scheduled),
              ].includes(String(values.g4b_type)),
            label: "Duration",
            required: true,
            type: "picklist",
            enum: TimeInterval,
          },
          {
            name: "g4b_numberofdays",
            description:
              "The number of consecutive days a pass product will allow an unlimited amount of entries.",
            displayIfFromState: (values) =>
              String(values.g4b_type) === String(ProductType.Pass),
            label: "Number of Days",
            type: "int",
          },
          {
            name: "g4b_startbuffer",
            description: "",
            displayIfFromState: (values) =>
              [
                String(ProductType.AutoScheduled),
                String(ProductType.Scheduled),
              ].includes(String(values.g4b_type)),
            label: "Start buffer",
            type: "int",
          },
          {
            name: "g4b_endbuffer",
            description: "",
            displayIfFromState: (values) =>
              [
                String(ProductType.AutoScheduled),
                String(ProductType.Scheduled),
              ].includes(String(values.g4b_type)),
            label: "End buffer",
            type: "int",
          },
          {
            name: "g4b_productminutesinthepast",
            description: "",
            displayIfFromState: (values) =>
              [
                String(ProductType.AutoScheduled),
                String(ProductType.Scheduled),
              ].includes(String(values.g4b_type)),
            label: "Minutes in the past",
            type: "int",
          },
          {
            name: "g4b_periodsuntilvalid",
            description:
              "The number of hours/days/weeks/months until the product can be used",
            displayIfFromState: (values) =>
              [
                String(ProductType.AutoScheduled),
                String(ProductType.Pass),
                String(ProductType.Scheduled),
              ].includes(String(values.g4b_type)),
            label: "Delay duration",
            type: "int",
          },
          {
            name: "g4b_period",
            description: "",
            displayIfFromState: (values) =>
              [
                String(ProductType.AutoScheduled),
                String(ProductType.Pass),
                String(ProductType.Scheduled),
              ].includes(String(values.g4b_type)),
            label: "Delay duration unit",
            type: "picklist",
            enum: DelayDurationUnit,
          },
          {
            name: "g4b_interval",
            description: "",
            displayIfFromState: (values) =>
              String(values.g4b_type) ===
              String(ProductType.AutoScheduled),
            label: "Interval",
            required: true,
            type: "picklist",
            enum: TimeInterval,
          },
          {
            name: "g4b_collectstartdate",
            default: false,
            description:
              "Prompt the user to select a start date for this product",
            displayIfFromState: (values) =>
              String(values.g4b_type) === String(ProductType.Pass),
            label: "Collect start date",
            type: "bit",
          },
          {
            allowAdd: true,
            allowEdit: true,
            name: "g4b_productcalendarid",
            description: "",
            displayIfFromState: (values) =>
              [
                String(ProductType.AutoScheduled),
                String(ProductType.Membership),
                String(ProductType.Scheduled),
              ].includes(String(values.g4b_type)) ||
              (String(values.g4b_type) === String(ProductType.Pass) &&
                values.g4b_collectstartdate),
            label: "Calendar",
            lookup: lookups.g4b_productcalendar.all,
            subFormStages: ProductCalendarFormStages,
            type: "lookup",
          },
        ],
      },
    ],
  },
  {
    title: "Grouping and channels",
    id: "grouping-and-channels",
    overviewColumn: OverviewColumn.Side,
    sections: [
      {
        name: "",
        fields: [
          {
            allowAdd: true,
            allowEdit: true,
            className: "col-12 mb-3",
            name: "g4b_categoryid",
            description: "This is a category to identify the product",
            label: "Category",
            lookup: lookups.g4b_analysiscategory.all,
            required: true,
            subFormStages: ProductCategoryFormStages,
            type: "lookup",
          },
          {
            className: "col-12 mb-3",
            name: "g4b_channel",
            description:
              "These are the sales channels through which the product can be bought",
            label: "Sales channels",
            inline: true,
            lookup: lookups.g4b_channel.all,
            type: "checklist",
          },
          {
            allowAdd: true,
            className: "col-12 mb-3",
            name: "g4_group",
            description:
              "These are the groups which the product will belong to",
            inline: true,
            label: "Groups",
            lookup: lookups.g4_group.widget,
            subFormStages: GroupFormStages,
            type: "checklist",
          },
          {
            allowAdd: true,
            className: "col-12 mb-3",
            name: "g4b_widgetgroup",
            description:
              "These are the widget groups which the product will belong to. These can be used to filter channel questions",
            inline: true,
            label: "Widget groups",
            lookup: lookups.g4b_widgetgroup.all,
            subFormStages: WidgetGroupFormStages,
            type: "checklist",
          },
          {
            allowAdd: true,
            pageRolesToCheck: "promotion-product-group",
            className: "col-12 mb-3",
            name: "g4b_productgroup",
            description:
              "These are the promotion groups which the product will belong to",
            inline: true,
            label: "Promotion product groups",
            lookup: lookups.g4b_productgroup.all,
            subFormStages: PromotionProductGroupFormStages,
            type: "checklist",
          },
        ],
      },
    ],
  },
  {
    title: "Zones",
    id: "zones",
    displayIfFromState: (values) =>
      [
        String(ProductType.AutoScheduled),
        String(ProductType.Membership),
        String(ProductType.Pass),
        String(ProductType.Scheduled),
      ].includes(String(values.g4b_type)),
    overviewColumn: OverviewColumn.Side,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_graceminutes",
            description:
              "The number of minutes that entrance before the session/pass/ticket start time begins. For example if you have a session that starts at 15:00 and you have a grace minute value set to 15, that means the customer will gain access before it begins from 14:45.",
            label: "Grace minutes",
            type: "int",
          },
          {
            name: "g4b_graceminutesafter",
            description:
              "The number of minutes that entrance after the session/pass/ticket start time begins. For example if you have a session that starts at 15:00 and you have a grace minutes after value set to 15, that means the customer will gain access before it after until 15:15.",
            label: "Grace minutes after",
            type: "int",
          },
          {
            allowAdd: true,
            pageRolesToCheck: "promotion-product-group",
            className: "col-12 mb-3",
            name: "g4b_zone",
            description:
              "These are the zones which the product will belong to",
            inline: true,
            label: "Zones",
            lookup: lookups.g4b_zone.all,
            subFormStages: ZoneFormStages,
            type: "checklist",
          },
          {
            name: "g4b_zoneName",
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    title: "Delivery and printing",
    id: "delivery-and-printing",
    displayIfFromState: (values) =>
      String(values.g4b_type) !== String(ProductType.Voucher),
    overviewColumn: OverviewColumn.Side,
    sections: [
      {
        name: "Delivery",
        fields: [
          {
            name: "g4b_type",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "g4b_requiresdelivery",
            default: false,
            displayAltDescription: (values) =>
              String(values.g4b_type) ===
              String(ProductType["Delivery Option"])
                ? "Set this delivery option product to require physical delivery such as postage"
                : "Prompt the user to select a delivery option for this product",
            label: "Requires delivery",
            type: "bit",
          },
        ],
      },
      {
        name: "Printing",
        fields: [
          {
            name: "g4b_printtransform",
            description:
              "The print transform that is used when printing for this product. If not set then it will default to one of the print transforms set in the terminal",
            label: "Print transform",
            lookup: lookups.g4b_printtransform.all,
            type: "lookup",
          },
          {
            name: "g4b_deliveryprintathome",
            default: false,
            displayAltDescription: (values) =>
              String(values.g4b_type) ===
              String(ProductType["Delivery Option"])
                ? "Set this delivery option product as a print at home delivery option"
                : "Allow the user to select the print at home delivery option for this product",
            displayIfFromValues: (values) =>
              [
                String(ProductType["Delivery Option"]),
                String(ProductType.AutoScheduled),
                String(ProductType.Scheduled),
                String(ProductType.Bundle),
              ].includes(String(values.g4b_type)) ||
              (values.g4b_requiresdelivery &&
                [
                  String(ProductType.Fixture),
                  String(ProductType.Series),
                  String(ProductType.Pass),
                  String(ProductType.Membership),
                ].includes(String(values.g4b_type))),
            displayTooltipDescription: (values) =>
              String(values.g4b_type) !==
              String(ProductType["Delivery Option"])
                ? `This only applies if the print at home option is enabled for the channel. A delivery option product with print at home set will need to be created. 
                ${
                  [
                    String(ProductType.Fixture),
                    String(ProductType.Series),
                  ].includes(String(values.g4b_type))
                    ? "And for fixture and series products, the print at home option on the venue configuration needs to also be enabled."
                    : ""
                }
                ${
                  [
                    String(ProductType.AutoScheduled),
                    String(ProductType.Scheduled),
                  ].includes(String(values.g4b_type))
                    ? "For AutoScheduled and Scheduled products, if this field is checked while the Requires Delivery field is unchecked, then on the booking website it will automatically add the print at home delivery option product to the basket alongside this product."
                    : ""
                }`
                : "",
            label: "Print at home",
            type: "bit",
          },
          {
            name: "g4b_mobilewallettickettypeid",
            description:
              "The ID for the template created in PassKit to allow this product to be printed via PassKit for mobile tickets",
            displayIfFromValues: (values) =>
              ((values.g4b_requiresdelivery &&
                [
                  String(ProductType.Fixture),
                  String(ProductType.Series),
                  String(ProductType.Pass),
                  String(ProductType.Membership),
                ].includes(String(values.g4b_type))) ||
                [
                  String(ProductType["Delivery Option"]),
                  String(ProductType.AutoScheduled),
                  String(ProductType.Scheduled),
                  String(ProductType.Bundle),
                ].includes(String(values.g4b_type))) &&
              values.g4b_deliveryprintathome &&
              String(values.g4b_type) !==
                String(ProductType["Delivery Option"]),
            label: "PassKit template ID",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Variants and pricing",
    id: "variants-and-pricing",
    editOnOverview: true,
    overviewColumn: OverviewColumn.Main,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_varianttypecategory",
            allowAdd: true,
            allowEdit: true,
            className: "col-md-6",
            description: "Category to filter the variant types",
            label: "Category",
            lookup: lookups.g4b_varianttypecategory.all,
            subFormStages: VariantTypeCategoryFormStages,
            type: "lookup",
          },
          {
            name: "g4b_mandatoryvariants",
            className: "col-md-6",
            description:
              "Indicates whether the product requires mandatory product variants to be added",
            displayIfFromState: (values) =>
              ![
                String(ProductType.Coupon),
                String(ProductType["Delivery Option"]),
                String(ProductType.Voucher),
              ].includes(String(values.g4b_type)),
            label: "Mandatory variants required",
            type: "bit",
          },
          {
            name: "productVariants",
            autoFillValue: (values) => {
              const productVariants = values.relatedEntities
                ? values.relatedEntities.find(
                    (r) => r.entityName === "g4b_protoproduct"
                  )
                : [];

              if (productVariants && productVariants.entities) {
                const variantPriceListItems =
                  values.relatedEntities.find(
                    (r) => r.entityName === "g4b_variantpricelistitem"
                  );

                const variantPriceListIds = Array.from(
                  new Set(
                    variantPriceListItems.entities.flatMap(
                      (obj) => obj.Fields.g4b_variantpricelistid
                    )
                  )
                );
                return productVariants.entities.map(
                  (productVariant) => {
                    return {
                      Id: productVariant.Id,
                      Code: productVariant.Fields.g4b_code || "",
                      MandatoryVariant:
                        productVariant.Fields.g4b_mandatoryvariant,
                      MarketingListId:
                        productVariant.Fields.g4b_marketinglist,
                      NotForSale:
                        productVariant.Fields.g4b_notforsale,
                      TaxCodeId: productVariant.Fields.g4b_taxcodeid,
                      VariantTypeId:
                        productVariant.Fields.g4b_varianttypeid,
                      PriceListItems: variantPriceListIds.map(
                        (variantPriceListId) => {
                          const variantPriceListItem =
                            variantPriceListItems.entities.find(
                              (x) =>
                                x.Fields.g4b_variantpricelistid ===
                                  variantPriceListId &&
                                x.Fields.g4b_varianttypeid ===
                                  productVariant.Fields
                                    .g4b_varianttypeid
                            );
                          return {
                            OffPeakPrice: variantPriceListItem
                              ? parseFloat(
                                  variantPriceListItem.Fields
                                    .g4b_offpeakprice
                                ).toFixed(2)
                              : "",
                            PeakPrice: variantPriceListItem
                              ? parseFloat(
                                  variantPriceListItem.Fields
                                    .g4b_peakprice
                                ).toFixed(2)
                              : "",
                            VariantPriceListId: variantPriceListId,
                            VariantTypeId:
                              productVariant.Fields.g4b_varianttypeid,
                          };
                        }
                      ),
                    };
                  }
                );
              }
              return "";
            },
            className: "col-12",
            component: ProductVariants,
            getLookups: (state) => {
              return [
                lookups.g4b_taxcode.all,
                // Memberships do not use variant price lists
                state.type !== 20
                  ? lookups.g4b_variantpricelist.all
                  : undefined,
                lookups.list.all,
              ].filter(Boolean);
            },
            type: "component",
            validate: (value) => {
              // validate the prices
              const pricingErrors = [];

              if (value) {
                value.forEach((productVariant) => {
                  const { PriceListItems, VariantTypeId } =
                    productVariant;
                  PriceListItems.forEach((priceListItem) => {
                    const {
                      OffPeakPrice,
                      PeakPrice,
                      VariantPriceListId,
                    } = priceListItem;
                    if (
                      OffPeakPrice &&
                      !OffPeakPrice.match(priceRegex)
                    ) {
                      pricingErrors.push({
                        PriceType: PriceType.OffPeakPrice,
                        VariantTypeId: VariantTypeId,
                        VariantPriceListId: VariantPriceListId,
                      });
                    }
                    if (PeakPrice && !PeakPrice.match(priceRegex)) {
                      pricingErrors.push({
                        PriceType: PriceType.PeakPrice,
                        VariantTypeId: VariantTypeId,
                        VariantPriceListId: VariantPriceListId,
                      });
                    }
                  });
                });
              }

              return pricingErrors;
            },
          },
          {
            name: "varianttype",
            allowAdd: true,
            allowEdit: true,
            description: "Select to add a variant type",
            ignoreOnSave: true,
            label: "Type",
            lookup: lookups.g4b_varianttype.all,
            mapOptionGroups: (options, state, values) => {
              const categories = getLookupData(
                lookups.g4b_varianttypecategory.all.name,
                state
              );

              return categories
                .filter(
                  (category) =>
                    // return categories which have variant types and are not filtered out by the category filter
                    options.some(
                      (option) =>
                        option.VariantTypeCategoryId === category.Key
                    ) &&
                    (!values.g4b_varianttypecategory ||
                      values.g4b_varianttypecategory === "" ||
                      values.g4b_varianttypecategory === category.Key)
                )
                .map((category) => ({
                  Key: category.Value,
                  Value: options.filter(
                    (option) =>
                      // return variant types which are in the category and not already added
                      option.VariantTypeCategoryId === category.Key &&
                      (!values.productVariants ||
                        !values.productVariants.some(
                          (productVariant) =>
                            productVariant.VariantTypeId ===
                            option.Key
                        ))
                  ),
                }));
            },
            subFormStages: VariantTypeFormStages,
            type: "lookup",
          },
        ],
      },
    ],
  },
  {
    title: "Product Modifiers",
    id: "product-modifiers",
    editOnOverview: true,
    renderStage: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (values) => values.id,
    overviewColumn: OverviewColumn.Main,
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          hideNameColumn: false,
          fields: [
            {
              name: "g4b_type",
              className: "col-filter",
              type: "picklist",
              enum: ProductModifierType,
            },
            {
              name: "g4b_isrequired",
              className: "col-bool",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_productbasemodifier",
          linkForNewRecord: "/product-modifier",
          linkedEntityId: "g4b_productbaseid",
          lookup: lookups.g4b_productbasemodifier.all,
          subFormStages: ProductModifierFormStages,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Cross Sell Products",
    id: "cross-sell-products",
    editOnOverview: true,
    renderStage: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (values) => values.id,
    overviewColumn: OverviewColumn.Main,
    sections: [
      {
        name: "Child cross sell products",
        description:
          "These are the products that will be displayed for cross sell if this product is added to the basket",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_childproductname",
              className: "col-name",
              label: "Child Product Name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_crosssellproduct-child",
          linkForNewRecord: "/cross-sell",
          linkedEntityId: "g4b_parentproduct",
          lookup: lookups.g4b_crosssellproduct.all,
          subFormStages: CrossSellProductFormStages,
          useSubForm: false,
        },
      },
      {
        name: "Parent cross sell products",
        description:
          "These are the product that when added will trigger this product as a cross sell product",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_parentproductname",
              className: "col-name",
              label: "Child Product Name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_crosssellproduct-parent",
          linkForNewRecord: "/cross-sell",
          linkedEntityId: "g4b_childproduct",
          lookup: lookups.g4b_crosssellproduct.all,
          subFormStages: CrossSellProductFormStages,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Reporting",
    id: "reporting",
    editOnOverview: true,
    overviewColumn: OverviewColumn.Side,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_reportname",
            autoFillValue: (values) =>
              values.g4b_name ? values.g4b_name.toUpperCase() : "",
            className: "col-12",
            description: "",
            label: "Report name",
            type: "nvarchar",
          },
          {
            name: "g4b_reportcategory1",
            allowAdd: true,
            allowEdit: true,
            className: "col-md-4",
            overviewClassName: "col-12",
            description: "",
            label: "Category 1",
            lookup: lookups.g4b_reportingcategory.all,
            subFormStages: ReportingCategoryFormStages,
            type: "lookup",
          },
          {
            name: "g4b_reportcategory2",
            className: "col-md-4",
            overviewClassName: "col-12",
            description: "",
            label: "Category 2",
            lookup: lookups.g4b_reportingcategory.all,
            type: "lookup",
          },
          {
            name: "g4b_reportcategory3",
            className: "col-md-4",
            overviewClassName: "col-12",
            description: "",
            label: "Category 3",
            lookup: lookups.g4b_reportingcategory.all,
            type: "lookup",
          },
          {
            name: "g4b_isadmission",
            className: "col-md-4",
            overviewClassName: "col-12",
            default: false,
            description:
              "This is for reporting purposes to indicate in the reports that this is a admission product and to allow for the amount of people scanned in to be seen.",
            displayIfFromState: (values) =>
              String(values.g4b_type) === String(ProductType.Pass),
            label: "Is admission",
            type: "bit",
          },
        ],
      },
    ],
  },
];
