import React, { useState } from "react";
import {
  useGlobalReducer,
  useGlobalState,
} from "../../../GlobalContext";
import { AlertStyle, GlobalDispatchMethods } from "../../../js/enums";
import { Alert } from "../../elements/_Elements";
import { JournalDailyReport } from "../../../js/service";
import { userHasCorrectRole } from "../../../js/roles";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue } from "../../../js/utility";
import JournalDailyReportsDatePicker from "./JournalDailyReportsDatePicker";
import JournalDailyReportsTable from "./JournalDailyReportsTable";
import {
  BookingsTableDetails,
  BookingsTotalErrorsTableDetails,
  JournalEntriesTableDetails,
  JournalPaymentsTableDetails,
  MembershipJournalEntriesTableDetails,
  MembershipJournalPaymentsTableDetails,
  ReprocessedBookingsTableDetails,
  SuccessfulReprocessedBookingsTableDetails,
} from "./Helpers";

export default function JournalDailyReports({ allowedRoles }) {
  const globalDispatch = useGlobalReducer();
  const globalState = useGlobalState();
  const { user } = globalState;
  const hasCorrectRole = userHasCorrectRole(user, allowedRoles);

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [data, setData] = useState(null);

  const handleSubmitFilter = async () => {
    setIsSubmitting(true);
    try {
      const [serviceResponse] = await Promise.all([
        JournalDailyReport(
          null,
          convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue(
            selectedStartDate
          ).valueOf()
        ),
      ]);
      if (serviceResponse && serviceResponse.data) {
        setData(serviceResponse.data);
      } else {
        setData([]);
        if (globalDispatch) {
          globalDispatch({
            type: GlobalDispatchMethods.AddNotification,
            notification: {
              message: "No Records Found",
              success: false,
            },
            dispatch: globalDispatch,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  return !hasCorrectRole ? (
    <DefaultLayout>
      {user && (
        <Alert
          className="m-3"
          style={AlertStyle.Danger}
          text={"Invalid Role"}
        /> //Only show the alert if the user has been loaded
      )}
    </DefaultLayout>
  ) : (
    <DefaultLayout>
      <form className={"d-flex flex-column min-h-100"}>
        <div id="form-page-header" className="sticky-top px-3">
          <div className="d-flex justify-content-start">
            <div className="d-flex align-items-center">
              <h2 className="fw-bold text-dark me-3">
                Journal Daily Reports
              </h2>
            </div>
          </div>
        </div>
        <div className="mb-auto">
          <div className="p-3 m-1 row">
            <JournalDailyReportsDatePicker
              handleSubmitFilter={handleSubmitFilter}
              isSubmitting={isSubmitting}
              selectedStartDate={selectedStartDate}
              setSelectedStartDate={setSelectedStartDate}
            />
            {data && (
              <>
                {data.Bookings && (
                  <JournalDailyReportsTable
                    data={data.Bookings}
                    tableDetails={BookingsTableDetails}
                  />
                )}
                {data.BookingsTotalErrors && (
                  <JournalDailyReportsTable
                    data={data.BookingsTotalErrors}
                    tableDetails={BookingsTotalErrorsTableDetails}
                  />
                )}
                {data.JournalEntries && (
                  <JournalDailyReportsTable
                    data={data.JournalEntries}
                    tableDetails={JournalEntriesTableDetails}
                  />
                )}
                {data.JournalPayments && (
                  <JournalDailyReportsTable
                    data={data.JournalPayments}
                    tableDetails={JournalPaymentsTableDetails}
                  />
                )}
                {data.MembershipJournalEntries && (
                  <JournalDailyReportsTable
                    data={data.MembershipJournalEntries}
                    tableDetails={
                      MembershipJournalEntriesTableDetails
                    }
                  />
                )}
                {data.MembershipJournalPayments && (
                  <JournalDailyReportsTable
                    data={data.MembershipJournalPayments}
                    tableDetails={
                      MembershipJournalPaymentsTableDetails
                    }
                  />
                )}
                {data.ReprocessedBookings && (
                  <JournalDailyReportsTable
                    data={data.ReprocessedBookings}
                    tableDetails={
                      ReprocessedBookingsTableDetails
                    }
                  />
                )}                
                {data.SuccessfulReprocessedBookings && (
                  <JournalDailyReportsTable
                    data={data.SuccessfulReprocessedBookings}
                    tableDetails={
                      SuccessfulReprocessedBookingsTableDetails
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      </form>
    </DefaultLayout>
  );
}
