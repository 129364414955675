import React, { useEffect, useState } from "react";
import { BackgroundStyle, ButtonStyle } from "../../../js/enums";
import {
  BadgeList,
  Button,
  Input,
  SelectWithFiltering,
} from "../../elements/_Elements";

function ModifierOptions({ setFieldValue, state, values }) {
  const [initialLoad, setInitialLoad] = useState(true);
  const [modifierOptionsArray, setModifierOptionsArray] = useState(
    []
  );
  const [defaultValueSelected, setDefaultValueSelected] =
    useState(null);
  const [currentInputValue, setCurrentInputValue] = useState("");

  useEffect(() => {
    if (initialLoad) {
      if (values.g4b_modifieroptions) {
        setModifierOptionsArray(
          values.g4b_modifieroptions.split(",")
        );
        //If values.g4b_defaultvalue is set to "true" then its most likely the case that
        //we have switched the type from Yes/No to Text while default value was checked to true,
        //which means we should clear the values.g4b_defaultvalue value
        setDefaultValueSelected(
          values.g4b_defaultvalue !== "true"
            ? values.g4b_defaultvalue
            : null
        );
        if (values.g4b_defaultvalue === "true") {
          setFieldValue("g4b_defaultvalue", null);
        }
      } else {
        setModifierOptionsArray([]);
        setDefaultValueSelected(null);
        setFieldValue("g4b_defaultvalue", null);
      }
      setInitialLoad(false);
    }
  }, [
    initialLoad,
    state.id,
    values.updateAction,
    setFieldValue,
    values.g4b_defaultvalue,
    values.g4b_modifieroptions,
  ]);

  const getBadgeItemList = () => {
    let result = [];
    if (modifierOptionsArray && modifierOptionsArray.length > 0) {
      modifierOptionsArray.forEach((m) => {
        result.push({
          text: m,
          backgroundStyle: BackgroundStyle.Info,
        });
      });
    }
    return result;
  };

  const onAddItem = () => {
    let updatedOptionsArray = [
      ...modifierOptionsArray,
      currentInputValue,
    ];
    setModifierOptionsArray(updatedOptionsArray);
    setFieldValue(
      "g4b_modifieroptions",
      updatedOptionsArray.toString()
    );
    setCurrentInputValue("");
  };

  const onDeleteBadge = (item) => {
    let updatedOptionsArray = modifierOptionsArray.filter(
      (m) => m !== item.text
    );
    setModifierOptionsArray(updatedOptionsArray);
    setFieldValue(
      "g4b_modifieroptions",
      updatedOptionsArray.toString()
    );

    if (updatedOptionsArray.length === 0) {
      setDefaultValueSelected(null);
      setFieldValue("g4b_defaultvalue", null);
    }
  };

  return (
    <>
      <div className="row mb-3">
        <Input
          className={"col-md-8"}
          label={"Modifier Options"}
          name={"modifieroptionsinput"}
          onChange={(event) =>
            setCurrentInputValue(event.target.value)
          }
          type={"text"}
          value={currentInputValue}
        />
        <Button
          className={"col-md-1 ms-2"}
          text={"Add"}
          style={ButtonStyle.Primary}
          onClick={() => onAddItem()}
          disabled={
            !currentInputValue ||
            currentInputValue.trim().length === 0
          }
        />
      </div>
      {modifierOptionsArray && modifierOptionsArray.length > 0 && (
        <div className="row mb-3">
          <BadgeList
            className="mb-3"
            items={getBadgeItemList()}
            onDeleteBadge={(item) => onDeleteBadge(item)}
          />
          <div className="col-md-4">
            <SelectWithFiltering
              label={"Modifier Options Default Value"}
              name={"modifier-options-default-value"}
              options={modifierOptionsArray.map((m, index) => ({
                Key: index,
                Value: m,
              }))}
              onChange={(event) => {
                setDefaultValueSelected(event.value);
                setFieldValue("g4b_defaultvalue", event.value);
              }}
              reverseLookup={true}
              value={defaultValueSelected}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ModifierOptions;
