import React, { useState } from "react";
import {
  AlertStyle,
  ButtonStyle,
  GlobalDispatchMethods,
} from "../../../js/enums";
import {
  IsExcluded,
  RemoveFromMailExclusion,
} from "../../../js/service";
import { useGlobalReducer } from "../../../GlobalContext";
import { Alert, Button, Input } from "../../elements/_Elements";

function MailExclusion() {
  const globalDispatch = useGlobalReducer();

  const [formState, setFormState] = useState({
    emailAddress: "",
    exclusion: null,
    isExcluded: false,
    isExcludedMessage: "",
    isRemoved: false,
    isRemovedMessage: "",
    isSubmitting: false,
    submittedEmailAddress: "",
  });

  const {
    emailAddress,
    exclusion,
    isExcluded,
    isExcludedMessage,
    isRemoved,
    isRemovedMessage,
    isSubmitting,
    submittedEmailAddress,
  } = formState;

  const CheckMailExclusion = async () => {
    if (!emailAddress) {
      return;
    }

    let isExcludedResponse = false;
    let isExcludedMessageResponse = "";
    let exclusionResponse = null;

    setFormState({
      ...formState,
      isExcluded: false,
      isExcludedMessage: "",
      isSubmitting: true,
    });
    try {
      const [serviceResponse] = await Promise.all([
        IsExcluded(null, emailAddress),
      ]);

      if (serviceResponse && serviceResponse.data) {
        const { ErrorMessage } = serviceResponse.data;
        if (ErrorMessage) {
          globalDispatch({
            type: GlobalDispatchMethods.AddNotification,
            notification: {
              message: ErrorMessage,
              success: false,
            },
            dispatch: globalDispatch,
          });
        } else {
          exclusionResponse = serviceResponse.data.Exclusion;
          isExcludedResponse = exclusionResponse !== null;
          isExcludedMessageResponse = isExcludedResponse
            ? `${emailAddress} is currently being excluded`
            : `${emailAddress} is currently not being excluded`;
        }
      } else {
        globalDispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: {
            message: "Error checking mail exclusion",
            success: false,
          },
          dispatch: globalDispatch,
        });
      }
    } catch (error) {
      console.error(error);
      globalDispatch({
        type: GlobalDispatchMethods.AddNotification,
        notification: {
          message: error,
          success: false,
        },
        dispatch: globalDispatch,
      });
    } finally {
      setFormState({
        ...formState,
        exclusion: exclusionResponse,
        isExcluded: isExcludedResponse,
        isExcludedMessage: isExcludedMessageResponse,
        isRemoved: false,
        isRemovedMessage: "",
        isSubmitting: false,
        submittedEmailAddress: emailAddress,
      });
    }
  };

  const RemoveEmailAddress = async () => {
    if (!submittedEmailAddress) {
      return;
    }

    let isRemovedResponse = false;
    let isRemovedMessageResponse = "";

    setFormState({
      ...formState,
      isRemoved: false,
      isRemovedMessage: "",
      isSubmitting: true,
    });
    try {
      const [serviceResponse] = await Promise.all([
        RemoveFromMailExclusion(null, submittedEmailAddress),
      ]);
      if (serviceResponse && serviceResponse.data) {
        const { ErrorMessage } = serviceResponse.data;
        if (ErrorMessage) {
          globalDispatch({
            type: GlobalDispatchMethods.AddNotification,
            notification: {
              message: ErrorMessage,
              success: false,
            },
            dispatch: globalDispatch,
          });
        } else {
          isRemovedResponse = serviceResponse.data.Removed;
          isRemovedMessageResponse = isRemovedResponse
            ? `${emailAddress} has been successfully removed from the mail exclusion`
            : `Unable to remove ${emailAddress} from the mail exclusion`;
        }
      } else {
        globalDispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: {
            message: "Error removing mail exclusion",
            success: false,
          },
          dispatch: globalDispatch,
        });
      }
    } catch (error) {
      console.error(error);
      globalDispatch({
        type: GlobalDispatchMethods.AddNotification,
        notification: {
          message: error,
          success: false,
        },
        dispatch: globalDispatch,
      });
    } finally {
      setFormState({
        ...formState,
        isExcluded: false,
        isExcludedMessage: "",
        isRemoved: isRemovedResponse,
        isRemovedMessage: isRemovedMessageResponse,
        isSubmitting: false,
      });
    }
  };

  return (
    <>
      <div className="mb-3">
        <Input
          label="Email Address"
          name="emailAddress"
          onChange={(event) =>
            setFormState({
              ...formState,
              emailAddress: event.target.value,
            })
          }
          value={emailAddress}
          disabled={isSubmitting}
        />
      </div>
      <Button
        disabled={isSubmitting}
        onClick={() => CheckMailExclusion()}
        showSpinner={isSubmitting}
        style={ButtonStyle.Primary}
        text={"Check Mail Exclusion"}
        type={"button"}
      />
      {isExcludedMessage && (
        <>
          {isExcluded ? (
            <>
              <Alert
                style={AlertStyle.Warning}
                text={isExcludedMessage}
              />
              {isExcluded && (
                <div className="mb-3">
                  <ul className="list-group d-inline-block">
                    <li className="list-group-item d-flex justify-content-between">
                      <strong className="me-3">Reason</strong>
                      <span>{exclusion.Reason}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <strong className="me-3">Esp</strong>
                      <span>{exclusion.Esp}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <strong className="me-3">Source</strong>
                      <span>{exclusion.Source}</span>
                    </li>
                  </ul>
                </div>
              )}
              <Button
                disabled={isSubmitting}
                onClick={() => RemoveEmailAddress()}
                showSpinner={isSubmitting}
                style={ButtonStyle.Primary}
                text="Remove"
                type={"button"}
              />
            </>
          ) : (
            <Alert
              style={AlertStyle.Success}
              text={isExcludedMessage}
            />
          )}
        </>
      )}
      {isRemovedMessage && (
        <>
          {isRemoved ? (
            <Alert
              style={AlertStyle.Success}
              text={isRemovedMessage}
            />
          ) : (
            <Alert
              style={AlertStyle.Danger}
              text={isRemovedMessage}
            />
          )}
        </>
      )}
    </>
  );
}

export default MailExclusion;
