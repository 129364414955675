import { AllYearsSelectHeader, PastYearsSelectHeader } from "../components/elements/_Elements";
import lookups from "./lookups";

export const OnHoldReasonFormStages = [
  {
    title: "On Hold Reason",
    id: "on-hold-reason",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-8",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_reasoncode",
            className: "col-md-4",
            description: "",
            label: "Reason Code",
            type: "nvarchar",
          },
          {
            name: "g4b_allowaccess",
            description: "",
            label: "Allow Access",
            type: "bit",
          },
          {
            name: "g4b_allowpurchase",
            description: "",
            label: "Allow Purchase",
            type: "bit",
          },
          {
            name: "g4b_allowticketallocation",
            description: "",
            label: "Allow Ticket Allocation",
            type: "bit",
          },
        ],
      },
      {
        name:"On Hold Audits",
        table: {
          allowAdd: false,
          allowEdit: true,
          allowStatusEdit: false,
          hideNameColumn: true,
          submitSearchOnLoad: true,
          useSubmitButton: true,
          fields: [
            {
              name: "createdon",
              className: "col-date",
              label: "On Hold Date",
              type: "datetime",
            },
            {
              name: "g4b_onhold_contactidname",
              className: "col-short-name",
              label: "Contact",
              type: "string",
            },
            {
              name: "g4b_additional_onhold_information",
              className: "col-short-name",
              label: "On Hold Information",
              type: "string",
            },
            {
              name: "g4b_datetimeoffhold",
              className: "col-date",
              label: "Off Hold Date",
              type: "datetime",
            },
            {
              name: "g4b_additional_offhold_information",
              className: "col-short-name",
              label: "Off Hold Information",
              type: "string",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              label: "On Hold Date",
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              label: "Off Hold Date",
              name: "g4b_datetimeoffhold",
              renderCustomHeader: AllYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          linkForNewRecord: "/on-hold-audit",
          lookup: lookups.g4b_onholdaudit.all,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      }
    ],
  },
];
