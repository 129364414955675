import { SeatNumberDirection, ZigZagStart } from "../../../js/enums";

const noSeatGuid = "00000000-0000-0000-0000-000000000000";
const hiddenSeatGuid = "00000000-0000-0000-0000-000000000001";

const seatSize = 50;

const loadSeatData = (
  setFieldValue,
  currentSeatData,
  setSeatData,
  values
) => {
  var seatData = {
    rows: values.g4b_rows || 10,
    seats: values.g4b_seats || 10,
    radius: values.g4b_radius || 100000,
    firstSeat: values.g4b_firstseat || 1,
    seatDirection: values.g4b_seatnumberdirection || 1,
    ignoreGaps: values.g4b_ignoregaps || false,
    zigzagStart: values.g4b_zigzagstart || 1,
  };
  addRowLabelsAndClasses(seatData, values);

  if (JSON.stringify(seatData) !== JSON.stringify(currentSeatData)) {
    setSeatData(seatData);
    setFieldValue("g4b_seatdata", JSON.stringify(seatData));
  }
};

function addRowLabelsAndClasses(seatData, values) {
  //Example values.g4b_seatdata info could be like the following:
  //{"rows":10,"seats":10,"radius":100000,"firstSeat":1,"seatDirection":1,"ignoreGaps":false,"zigzagStart":1,
  //"rowLabels":["A","B","C","D","E","F","G","H","I","J"],
  //"classIndexes":["e674e3e5-c13c-ec11-8104-000d3ade3d5a","00000000-0000-0000-0000-000000000000",
  //"00000000-0000-0000-0000-000000000001","d4396d94-a5cd-4a47-8aec-3083bf5594e1"],
  //"classes":[[0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,3,0,0,0,0],[0,0,0,0,0,0,0,0,0,0],[0,0,0,0,1,0,2,0,0,0],[0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0]],
  //"validSeatsPerRow":[10,10,10,10,10,10,9,10,10,10]}
  //Main info we want for this is the rowLabels, classIndexes and classes as we want to map those
  //bits of info to the current rows and seats count layout as well as calculating the validSeatsPerRow
  var seatDataValues = values.g4b_seatdata
    ? JSON.parse(values.g4b_seatdata)
    : {};

  var seatClasses =
    seatDataValues && seatDataValues.classIndexes
      ? seatDataValues.classIndexes
      : [noSeatGuid];
  var rowLabels = new Array(seatData.rows);
  var classes = new Array(seatData.rows);
  let validSeatsPerRow = new Array(seatData.rows);

  for (var r = 0; r < seatData.rows; r++) {
    var vsc = 0; // valid seat count for this row
    rowLabels[r] =
      seatDataValues &&
      seatDataValues.rowLabels &&
      seatDataValues.rowLabels.length > 0 &&
      seatDataValues.rowLabels.length > r
        ? seatDataValues.rowLabels[r]
        : "";
    classes[r] = new Array(seatData.seats);
    for (var s = 0; s < seatData.seats; s++) {
      classes[r][s] =
        seatDataValues &&
        seatDataValues.classes &&
        seatDataValues.classes.length > 0 &&
        seatDataValues.classes.length > r &&
        seatDataValues.classes[r] &&
        seatDataValues.classes[r].length > 0 &&
        seatDataValues.classes[r].length > s
          ? seatDataValues.classes[r][s]
          : 0;
      let seatClassOfCurrentSeat = seatClasses[classes[r][s]];
      if (seatClassOfCurrentSeat !== noSeatGuid) {
        vsc++;
      }
    }
    validSeatsPerRow[r] = Number(vsc);
  }

  seatData.rowLabels = rowLabels;
  seatData.classIndexes = seatClasses;
  seatData.classes = classes;
  seatData.validSeatsPerRow = validSeatsPerRow;
}

function getBlockDimensions(rows, seats) {
  return {
    height: (rows + 2) * seatSize,
    width: seats * seatSize + 100,
  };
}

function getSeatBackgroundColour(
  rowIndex,
  seatClasses,
  seatData,
  seatIndex
) {
  const selectedSeatClassId =
    seatData.classIndexes[seatData.classes[rowIndex][seatIndex]];
  const matchedSeatClass = seatClasses.find(
    (s) => s.Id === selectedSeatClassId
  );

  return matchedSeatClass &&
    matchedSeatClass.Fields &&
    matchedSeatClass.Fields.g4b_colour
    ? matchedSeatClass.Fields.g4b_colour
    : selectedSeatClassId === noSeatGuid
    ? "lightgrey"
    : "white";
}

function getSeatClassOptions(seatClasses) {
  return [
    {
      Id: noSeatGuid,
      Name: "No Seat",
      Color: "lightgrey",
    },
    {
      Id: hiddenSeatGuid,
      Name: "Hidden Seat",
      Color: "white",
    },
    ...(seatClasses && seatClasses.length > 0
      ? seatClasses.map((s) => {
          return {
            Id: s.Id,
            Name: s.Name,
            Color: s.Fields.g4b_colour,
          };
        })
      : []),
  ];
}

function getSeatLabel(rowIndex, seatIndex, seatData) {
  const seatNumber = getSeatNumber(rowIndex, seatIndex, seatData);
  return seatNumber >= 0
    ? `${seatData.rowLabels[rowIndex]} ${seatNumber}`
    : "";
}

function getSeatAngle(seatIndex, seats, rowRadius) {
  const outsideRow = seatSize * (seats - 1);

  const area = rowRadius * 2.0 * Math.PI;

  const segmentAngle = (outsideRow * 360.0) / area;

  const segmentSeatAngle = (seatSize * 360.0) / area;

  const seatAngle =
    segmentAngle / -2.0 - 90.0 + seatIndex * segmentSeatAngle;

  return (2.0 * Math.PI * seatAngle) / 360.0;
}

function getLeftPosition(rowIndex, seatIndex, seatData) {
  const { seats, radius } = seatData;

  const rowRadius = radius + rowIndex * seatSize;

  const seatAngle = getSeatAngle(seatIndex, seats, rowRadius);

  const offset = (seatData.seats * seatSize) / 2;

  const x =
    radius >= 10000
      ? seatIndex * seatSize + seatSize / 2
      : Math.cos(seatAngle) * rowRadius + offset;

  return `${x}px`;
}

function getTopPosition(rowIndex, seatIndex, seatData) {
  const { seats, radius } = seatData;

  const rowRadius = radius + rowIndex * seatSize;

  const seatAngle =
    radius >= 10000
      ? -1.0 * (Math.PI / 2)
      : getSeatAngle(seatIndex, seats, rowRadius);

  const maxRadius = seatData.radius + seatData.rows * seatSize;

  const y = maxRadius + Math.sin(seatAngle) * rowRadius;

  return `${y}px`;
}

function getSeatNumber(rowIndex, seatIndex, seatData) {
  var seat = 0;
  var rowdir = 0; // Flag which direction the row runs. It is dependant on which zig zag start direction
  //is chosen as well as the current row
  if (!seatData.ignoreGaps) {
    seat = seatData.firstSeat + seatIndex; //Default seat direction of left to right
    if (
      seatData.seatDirection === SeatNumberDirection["Right to Left"]
    ) {
      seat = seatData.seats - (seatIndex + 1) + seatData.firstSeat;
    } else if (
      seatData.seatDirection === SeatNumberDirection["Zig Zag"]
    ) {
      if (
        seatData.zigzagStart === ZigZagStart["Top Left"] ||
        seatData.zigzagStart === ZigZagStart["Top Right"]
      ) {
        for (var i = seatData.rows - 1; i > rowIndex; i--) {
          rowdir = 1 - rowdir;
        }
      } else {
        for (var j = 0; j < rowIndex; j++) {
          rowdir = 1 - rowdir;
        }
      }
      // Now calculate the seat number which depends on start position
      // and whether actual row is Left to Right or Right to Left
      if (seatData.zigzagStart === ZigZagStart["Bottom Right"]) {
        seat = seatData.firstSeat + rowIndex * seatData.seats;
        if (rowdir === 1) {
          //To the right
          seat += seatIndex;
        } else {
          //To the left
          seat += seatData.seats - seatIndex - 1;
        }
      } else if (seatData.zigzagStart === ZigZagStart["Top Left"]) {
        seat =
          seatData.firstSeat +
          (seatData.rows - rowIndex - 1) * seatData.seats;
        if (rowdir === 1) {
          //To the left
          seat += seatData.seats - seatIndex - 1;
        } else {
          //To the right
          seat += seatIndex;
        }
      } else if (seatData.zigzagStart === ZigZagStart["Top Right"]) {
        seat =
          seatData.firstSeat +
          (seatData.rows - rowIndex - 1) * seatData.seats;
        if (rowdir === 1) {
          //To the right
          seat += seatIndex;
        } else {
          //To the left
          seat += seatData.seats - seatIndex - 1;
        }
      } else {
        seat = seatData.firstSeat + rowIndex * seatData.seats;
        if (rowdir === 1) {
          //To the left
          seat += seatData.seats - seatIndex - 1;
        } else {
          //To the right
          seat += seatIndex;
        }
      }
    }
  } else {
    var count = -1;
    let seatClassOfCurrentSeat = getMatchingSeatClassGuid(
      seatData,
      rowIndex,
      seatIndex
    );
    if (seatClassOfCurrentSeat !== noSeatGuid) {
      //Incremenet the count based on whether the seat class of the other seats is not
      //set to the no seat value
      let seatClassOfOtherSeat = null;
      if (
        seatData.seatDirection ===
        SeatNumberDirection["Left to Right"]
      ) {
        for (var k = 0; k < seatData.seats && k <= seatIndex; k++) {
          seatClassOfOtherSeat = getMatchingSeatClassGuid(
            seatData,
            rowIndex,
            k
          );
          if (seatClassOfOtherSeat !== noSeatGuid) {
            count++;
          }
        }
      } else if (
        seatData.seatDirection ===
        SeatNumberDirection["Right to Left"]
      ) {
        for (
          var l = seatData.seats - 1;
          l >= 0 && l >= seatIndex;
          l--
        ) {
          seatClassOfOtherSeat = getMatchingSeatClassGuid(
            seatData,
            rowIndex,
            l
          );
          if (seatClassOfOtherSeat !== noSeatGuid) {
            count++;
          }
        }
      } else if (
        seatData.seatDirection === SeatNumberDirection["Zig Zag"]
      ) {
        // Flag which direction the row runs and also count valid seats for complete rows
        if (
          seatData.zigzagStart === ZigZagStart["Top Left"] ||
          seatData.zigzagStart === ZigZagStart["Top Right"]
        ) {
          // start numbering from the top
          for (var m = seatData.rows - 1; m > rowIndex; m--) {
            rowdir = 1 - rowdir;
            count += seatData.validSeatsPerRow[m];
          }
        } else {
          // start numbering from the bottom
          for (var n = 0; n < rowIndex; n++) {
            rowdir = 1 - rowdir;
            count += seatData.validSeatsPerRow[n];
          }
        }
        // count for target row
        if (seatData.zigzagStart === ZigZagStart["Bottom Right"]) {
          if (rowdir === 1) {
            //To the right
            for (var s = 0; s <= seatIndex; s++) {
              seatClassOfOtherSeat = getMatchingSeatClassGuid(
                seatData,
                rowIndex,
                s
              );
              if (seatClassOfOtherSeat !== noSeatGuid) {
                count++;
              }
            }
          } else {
            //To the left
            for (var t = seatData.seats - 1; t >= seatIndex; t--) {
              seatClassOfOtherSeat = getMatchingSeatClassGuid(
                seatData,
                rowIndex,
                t
              );
              if (seatClassOfOtherSeat !== noSeatGuid) {
                count++;
              }
            }
          }
        } else if (seatData.zigzagStart === ZigZagStart["Top Left"]) {
          if (rowdir === 1) {
            //To the left
            for (var u = seatData.seats - 1; u >= seatIndex; u--) {
              seatClassOfOtherSeat = getMatchingSeatClassGuid(
                seatData,
                rowIndex,
                u
              );
              if (seatClassOfOtherSeat !== noSeatGuid) {
                count++;
              }
            }
          } else {
            //To the right
            for (var v = 0; v <= seatIndex; v++) {
              seatClassOfOtherSeat = getMatchingSeatClassGuid(
                seatData,
                rowIndex,
                v
              );
              if (seatClassOfOtherSeat !== noSeatGuid) {
                count++;
              }
            }
          }
        } else if (
          seatData.zigzagStart === ZigZagStart["Top Right"]
        ) {
          if (rowdir === 1) {
            //To the right
            for (var w = 0; w <= seatIndex; w++) {
              seatClassOfOtherSeat = getMatchingSeatClassGuid(
                seatData,
                rowIndex,
                w
              );
              if (seatClassOfOtherSeat !== noSeatGuid) {
                count++;
              }
            }
          } else {
            //To the left
            for (var x = seatData.seats - 1; x >= seatIndex; x--) {
              seatClassOfOtherSeat = getMatchingSeatClassGuid(
                seatData,
                rowIndex,
                w
              );
              if (seatClassOfOtherSeat !== noSeatGuid) {
                count++;
              }
            }
          }
        } else {
          // default, bottom left
          if (rowdir === 1) {
            //To the left
            for (var y = seatData.seats - 1; y >= seatIndex; y--) {
              seatClassOfOtherSeat = getMatchingSeatClassGuid(
                seatData,
                rowIndex,
                y
              );
              if (seatClassOfOtherSeat !== noSeatGuid) {
                count++;
              }
            }
          } else {
            //To the right
            for (var z = 0; z <= seatIndex; z++) {
              seatClassOfOtherSeat = getMatchingSeatClassGuid(
                seatData,
                rowIndex,
                z
              );
              if (seatClassOfOtherSeat !== noSeatGuid) {
                count++;
              }
            }
          }
        }
      }
      if (count >= 0) {
        seat = Number(seatData.firstSeat) + Number(count);
      }
    } else {
      seat = -1;
    }
  }

  return seat;
}

function getMatchingSeatClassGuid(seatData, row, column) {
  return (seatData.classes[row][column] ||
    seatData.classes[row][column] === 0) &&
    seatData.classIndexes.length > seatData.classes[row][column] &&
    seatData.classIndexes[seatData.classes[row][column]]
    ? seatData.classIndexes[seatData.classes[row][column]]
    : noSeatGuid; //Default to no seat if no value found
}

export {
  hiddenSeatGuid,
  loadSeatData,
  noSeatGuid,
  getBlockDimensions,
  getLeftPosition,
  getSeatBackgroundColour,
  getSeatClassOptions,
  getSeatLabel,
  getSeatNumber,
  getTopPosition,
};
