import { useState } from "react";
import classNames from "classnames";
import {
  checkForValidRoles,
  getConfirmationModal,
} from "../form/Helpers";
import { EntityInformation, Loading } from "./_Elements";
import { LoadingState } from "../../js/enums";
import {
  getHeaderComponent,
  getNavigationButtons,
} from "../../js/utility";
import { useGlobalState } from "../../GlobalContext";
import { administratorRoles } from "../../js/roles";

function SubmitFormPage({
  auditLabel,
  backText,
  children,
  customCopyComponent,
  dispatch,
  disabled,
  entityName,
  errors,
  filteredStages,
  header,
  headerComponent,
  id,
  informationComponent,
  isBusy,
  loading,
  onBack,
  onSubmit,
  onSubmitSubForm,
  scrollToElement,
  setFieldValue,
  setLoading,
  showButtonSection,
  showInfo,
  showSaveAndCloseButton,
  showSaveButton,
  stage,
  stages,
  state,
  submitText,
  submitButtonClickEvent,
  saveAndCloseSubmitButtonClickEvent,
  values,
  showCopyButton,
  showSaveAndNewButton,
  saveAndNewSubmitButtonClickEvent,
}) {
  const globalState = useGlobalState();

  const [showInformation, setShowInformation] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(null);

  const allowShowInformation =
    showInfo &&
    state.id &&
    checkForValidRoles(administratorRoles, globalState);

  const HeaderComponent = headerComponent
    ? headerComponent
    : getHeaderComponent(entityName, onSubmitSubForm);

  const NavigationButtons = getNavigationButtons(entityName);

  const InformationComponent = informationComponent
    ? informationComponent
    : EntityInformation;

  return (
    <>
      {confirmationModal &&
        confirmationModal.displayModal &&
        getConfirmationModal(
          confirmationModal,
          onSubmit,
          setConfirmationModal,
          setFieldValue,
          state,
          values
        )}
      <form
        id={id}
        onSubmit={onSubmit}
        className={classNames(
          "d-flex",
          "flex-column",
          "min-h-100",
          disabled ? "d-none" : ""
        )}
      >
        <HeaderComponent
          {...{
            allowShowInformation,
            dispatch,
            entityName,
            filteredStages,
            header,
            loading,
            onBack,
            scrollToElement,
            setLoading,
            setShowInformation,
            showInformation,
            stages,
            state,
            values,
          }}
        />
        <div className="mb-auto">
          <div className="p-3 m-1 row">
            {loading !== LoadingState.Loaded ? (
              <Loading />
            ) : showInformation ? (
              <InformationComponent
                {...{ state }}
                label={auditLabel ? auditLabel : entityName}
              />
            ) : (
              children
            )}
          </div>
        </div>
        {loading === LoadingState.Loaded &&
          showButtonSection &&
          !showInformation && (
            <NavigationButtons
              backText={backText}
              customCopyComponent={customCopyComponent}
              disabled={disabled}
              entityName={entityName}
              errors={errors}
              isBusy={isBusy}
              onBack={onBack}
              onSubmit={onSubmit}
              setConfirmationModal={setConfirmationModal}
              setFieldValue={setFieldValue}
              showSaveAndCloseButton={showSaveAndCloseButton}
              showSaveButton={showSaveButton}
              stage={stage}
              stages={stages}
              state={state}
              submitText={submitText}
              submitButtonClickEvent={submitButtonClickEvent}
              saveAndCloseSubmitButtonClickEvent={
                saveAndCloseSubmitButtonClickEvent
              }
              values={values}
              showCopyButton={showCopyButton}
              showSaveAndNewButton={showSaveAndNewButton}
              saveAndNewSubmitButtonClickEvent={
                saveAndNewSubmitButtonClickEvent
              }
            />
          )}
      </form>
    </>
  );
}

export default SubmitFormPage;
