import { Button } from "../../elements/_Elements";
import { ButtonStyle } from "../../../js/enums";
import "../../elements/FormPageHeader.scss";

function BlacklistPageHeader({
  header,
  setShowInformation,
  showInformation,
}) {
  return (
    <>
      <div id="form-page-header" className="sticky-top px-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {header && (
              <h2 className="fw-bold text-dark me-3">{header}</h2>
            )}
          </div>
          <div className="d-flex align-items-end">
            {showInformation ? (
              <Button
                onClick={() => setShowInformation(false)}
                text="Close"
              />
            ) : (
              <Button
                onClick={() => setShowInformation(true)}
                style={ButtonStyle.OutlinePrimary}
                text="Audits"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BlacklistPageHeader;
