import lookups from "../../../forms/lookups";
import { ProductType } from "../../../js/enums";
import { getLookupData } from "../../../js/utility";

const bookableProductsSearchFilterArray = [
  {
    name: "g4b_name",
    description: "",
    label: "Name",
  },
  {
    name: "g4b_code",
    description: "",
    label: "Code",
  },
  {
    name: "g4b_type",
    enum: ProductType,
    label: "Product Type",
    type: "select",
  },
];

const bookableProductsTableColumns = [
  {
    headingName: "Name",
    className: "col-primary",
    isNameField: true,
    type: "string",
    linkById: true,
    linkedEntityPath: "/product",
    openInNewTab: true,
  },
  {
    headingName: "Code",
    className: "col-short-name",
    fieldName: "code",
    type: "string",
  },
  {
    headingName: "Category",
    className: "col-short-name",
    fieldName: "category",
    type: "string",
  },
  {
    headingName: "Type",
    className: "col-short-name",
    fieldName: "type",
    type: "string",
  },
];

const optionVariantsSearchFilterArray = [
  {
    name: "g4b_name",
    description: "",
    label: "Name",
  },
  {
    name: "g4b_varianttypeid",
    description: "",
    label: "Variant Type",
    lookup: lookups.g4b_varianttype.all,
    mapOptionGroups: (_, state) => {
      const categories = getLookupData(
        lookups.g4b_varianttypecategory.all.name,
        state
      );
      const variantTypes = getLookupData(
        lookups.g4b_varianttype.all.name,
        state
      );
      return categories
        .filter((category) =>
          variantTypes.some(
            (option) => option.VariantTypeCategoryId === category.Key
          )
        )
        .map((category) => ({
          Key: category.Value,
          Value: variantTypes.filter(
            (option) =>
              // return variant types which are in the category
              option.VariantTypeCategoryId === category.Key
          ),
        }));
    },
    type: "select",
  },
];

const optionVariantsTableColumns = [
  {
    headingName: "Name",
    className: "col-primary",
    isNameField: true,
    type: "string",
  },
  {
    headingName: "Variant Type",
    className: "col-short-name",
    fieldName: "varianttype",
    type: "string",
  },
];

export {
  bookableProductsSearchFilterArray,
  bookableProductsTableColumns,
  optionVariantsSearchFilterArray,
  optionVariantsTableColumns,
};
