import React, { useState } from "react";
import moment from "moment";
import { AlertStyle, Gender } from "../../../js/enums";
import { convertDayFormatForMoment } from "../../../js/utility";
import Alert from "../../elements/Alert";

function MergeContactsFieldSelectionTable({
  selectedAttributes,
  selectedChildContact,
  setSelectedAttributes,
  state,
}) {
  //The loading state is used to refresh the table with the updated selected attributes
  //as without it, the table doesn't get updated with the selected values despite the
  //selectedAttributes state being updated
  const [loading, setLoading] = useState(false);

  const setSelectedAttributeProperty = (property, value) => {
    let updatedSelectedAttributes = selectedAttributes;
    updatedSelectedAttributes[property] = value;
    setSelectedAttributes(updatedSelectedAttributes);
    setLoading(true);
  };

  if (!selectedAttributes || loading) {
    setLoading(false);
    return <></>;
  }

  return (
    <>
      <hr className="hr" />
      <Alert
        className="m-3"
        style={AlertStyle.Warning}
        text={
          <small>
            The following will be merged from{" "}
            <strong>{selectedChildContact.Name}</strong> to{" "}
            <strong>{state.name}</strong>:
            <ul>
              <li>Addresses</li>
              <li>Booking History</li>
              <li>Communication Activities</li>
              <li>Communication Audits</li>
              <li>Marketing Lists</li>
              <li>Membership Accounts</li>
              <li>Memberships</li>
              <li>Payment Mandates</li>
              <li>Gift Aid Declarations</li>
              <li>Coupons</li>
              <li>Vouchers</li>
              <li>Contact Audits</li>
              <li>On Hold Audits</li>
            </ul>
            You can also select which values to set in{" "}
            <strong>{state.name}</strong>. Once the merge is complete,{" "}
            <strong>{selectedChildContact.Name}</strong> will be
            deactivated.
          </small>
        }
      />
      <div className="row mt-3 mb-2">
        <div className="col-md-4">
          <strong>Key:</strong>
          <table className="table">
            <tbody>
              <tr>
                <td className="table-success">Selected</td>
                <td>Not Selected</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {
        <div className="list-view">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr className="table-info">
                  <th scope="col" className={"col-short-name"}>
                    Fields
                  </th>
                  <th scope="col" className={"col-name"}>
                    {state.name}
                  </th>
                  <th scope="col" className={"col-name"}>
                    {selectedChildContact.Name}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">First Name</th>
                  <td
                    className={
                      selectedAttributes.firstname === state.firstname
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "firstname",
                        state.firstname
                      )
                    }
                  >
                    {state.firstname ? state.firstname : ""}
                  </td>
                  <td
                    className={
                      selectedAttributes.firstname ===
                      selectedChildContact.Fields["firstname"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "firstname",
                        selectedChildContact.Fields["firstname"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["firstname"]
                      ? selectedChildContact.Fields["firstname"]
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Middle Name</th>
                  <td
                    className={
                      selectedAttributes.middlename ===
                      state.middlename
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "middlename",
                        state.middlename
                      )
                    }
                  >
                    {state.middlename ? state.middlename : ""}
                  </td>
                  <td
                    className={
                      selectedAttributes.middlename ===
                      selectedChildContact.Fields["middlename"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "middlename",
                        selectedChildContact.Fields["middlename"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["middlename"]
                      ? selectedChildContact.Fields["middlename"]
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Last Name</th>
                  <td
                    className={
                      selectedAttributes.lastname === state.lastname
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "lastname",
                        state.lastname
                      )
                    }
                  >
                    {state.lastname ? state.lastname : ""}
                  </td>
                  <td
                    className={
                      selectedAttributes.lastname ===
                      selectedChildContact.Fields["lastname"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "lastname",
                        selectedChildContact.Fields["lastname"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["lastname"]
                      ? selectedChildContact.Fields["lastname"]
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Email Address</th>
                  <td
                    className={
                      selectedAttributes.emailaddress1 ===
                      state.emailaddress1
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "emailaddress1",
                        state.emailaddress1
                      )
                    }
                  >
                    {state.emailaddress1 ? state.emailaddress1 : ""}
                  </td>
                  <td
                    className={
                      selectedAttributes.emailaddress1 ===
                      selectedChildContact.Fields["emailaddress1"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "emailaddress1",
                        selectedChildContact.Fields["emailaddress1"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["emailaddress1"]
                      ? selectedChildContact.Fields["emailaddress1"]
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Gender</th>
                  <td
                    className={
                      selectedAttributes.gendercode ===
                      state.gendercode
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "gendercode",
                        state.gendercode
                      )
                    }
                  >
                    {state.gendercode
                      ? Object.keys(Gender).find(
                          (key) => Gender[key] === state.gendercode
                        )
                      : ""}
                  </td>
                  <td
                    className={
                      selectedAttributes.gendercode ===
                      selectedChildContact.Fields["gendercode"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "gendercode",
                        selectedChildContact.Fields["gendercode"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["gendercode"]
                      ? Object.keys(Gender).find(
                          (key) =>
                            Gender[key] ===
                            selectedChildContact.Fields["gendercode"]
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Business Phone</th>
                  <td
                    className={
                      selectedAttributes.telephone1 ===
                      state.telephone1
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "telephone1",
                        state.telephone1
                      )
                    }
                  >
                    {state.telephone1 ? state.telephone1 : ""}
                  </td>
                  <td
                    className={
                      selectedAttributes.telephone1 ===
                      selectedChildContact.Fields["telephone1"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "telephone1",
                        selectedChildContact.Fields["telephone1"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["telephone1"]
                      ? selectedChildContact.Fields["telephone1"]
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Home Phone</th>
                  <td
                    className={
                      selectedAttributes.telephone2 ===
                      state.telephone2
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "telephone2",
                        state.telephone2
                      )
                    }
                  >
                    {state.telephone2 ? state.telephone2 : ""}
                  </td>
                  <td
                    className={
                      selectedAttributes.telephone2 ===
                      selectedChildContact.Fields["telephone2"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "telephone2",
                        selectedChildContact.Fields["telephone2"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["telephone2"]
                      ? selectedChildContact.Fields["telephone2"]
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Mobile Phone</th>
                  <td
                    className={
                      selectedAttributes.mobilephone ===
                      state.mobilephone
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "mobilephone",
                        state.mobilephone
                      )
                    }
                  >
                    {state.mobilephone ? state.mobilephone : ""}
                  </td>
                  <td
                    className={
                      selectedAttributes.mobilephone ===
                      selectedChildContact.Fields["mobilephone"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "mobilephone",
                        selectedChildContact.Fields["mobilephone"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["mobilephone"]
                      ? selectedChildContact.Fields["mobilephone"]
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Job Title</th>
                  <td
                    className={
                      selectedAttributes.jobtitle === state.jobtitle
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "jobtitle",
                        state.jobtitle
                      )
                    }
                  >
                    {state.jobtitle ? state.jobtitle : ""}
                  </td>
                  <td
                    className={
                      selectedAttributes.jobtitle ===
                      selectedChildContact.Fields["jobtitle"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "jobtitle",
                        selectedChildContact.Fields["jobtitle"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["jobtitle"]
                      ? selectedChildContact.Fields["jobtitle"]
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Birthdate</th>
                  <td
                    className={
                      selectedAttributes.birthdate === state.birthdate
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "birthdate",
                        state.birthdate
                      )
                    }
                  >
                    {state.birthdate
                      ? moment(state.birthdate).format(
                          convertDayFormatForMoment("dd/MM/yyyy")
                        )
                      : ""}
                  </td>
                  <td
                    className={
                      selectedAttributes.birthdate ===
                      selectedChildContact.Fields["birthdate"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "birthdate",
                        selectedChildContact.Fields["birthdate"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["birthdate"]
                      ? moment(
                          selectedChildContact.Fields["birthdate"],
                          "DD/MM/yyyy"
                        ).format(
                          convertDayFormatForMoment("dd/MM/yyyy")
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Send Emails</th>
                  <td
                    className={
                      selectedAttributes.donotemail ===
                      state.donotemail
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "donotemail",
                        state.donotemail
                      )
                    }
                  >
                    {state.donotemail ? "Yes" : "No"}
                  </td>
                  <td
                    className={
                      selectedAttributes.donotemail ===
                      selectedChildContact.Fields["donotemail"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "donotemail",
                        selectedChildContact.Fields["donotemail"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["donotemail"]
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Send Bulk Emails</th>
                  <td
                    className={
                      selectedAttributes.donotbulkemail ===
                      state.donotbulkemail
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "donotbulkemail",
                        state.donotbulkemail
                      )
                    }
                  >
                    {state.donotbulkemail ? "Yes" : "No"}
                  </td>
                  <td
                    className={
                      selectedAttributes.donotbulkemail ===
                      selectedChildContact.Fields["donotbulkemail"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "donotbulkemail",
                        selectedChildContact.Fields["donotbulkemail"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["donotbulkemail"]
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Send Mail</th>
                  <td
                    className={
                      selectedAttributes.donotpostalmail ===
                      state.donotpostalmail
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "donotpostalmail",
                        state.donotpostalmail
                      )
                    }
                  >
                    {state.donotpostalmail ? "Yes" : "No"}
                  </td>
                  <td
                    className={
                      selectedAttributes.donotpostalmail ===
                      selectedChildContact.Fields["donotpostalmail"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "donotpostalmail",
                        selectedChildContact.Fields["donotpostalmail"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["donotpostalmail"]
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Send Bulk Mail</th>
                  <td
                    className={
                      selectedAttributes.donotbulkpostalmail ===
                      state.donotbulkpostalmail
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "donotbulkpostalmail",
                        state.donotbulkpostalmail
                      )
                    }
                  >
                    {state.donotbulkpostalmail ? "Yes" : "No"}
                  </td>
                  <td
                    className={
                      selectedAttributes.donotbulkpostalmail ===
                      selectedChildContact.Fields[
                        "donotbulkpostalmail"
                      ]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "donotbulkpostalmail",
                        selectedChildContact.Fields[
                          "donotbulkpostalmail"
                        ]
                      )
                    }
                  >
                    {selectedChildContact.Fields[
                      "donotbulkpostalmail"
                    ]
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Allow Phone Calls</th>
                  <td
                    className={
                      selectedAttributes.donotphone ===
                      state.donotphone
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "donotphone",
                        state.donotphone
                      )
                    }
                  >
                    {state.donotphone ? "Yes" : "No"}
                  </td>
                  <td
                    className={
                      selectedAttributes.donotphone ===
                      selectedChildContact.Fields["donotphone"]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "donotphone",
                        selectedChildContact.Fields["donotphone"]
                      )
                    }
                  >
                    {selectedChildContact.Fields["donotphone"]
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Allow Third Party Phone Calls</th>
                  <td
                    className={
                      selectedAttributes.g4_donotphone3rdparty ===
                      state.g4_donotphone3rdparty
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "g4_donotphone3rdparty",
                        state.g4_donotphone3rdparty
                      )
                    }
                  >
                    {state.g4_donotphone3rdparty ? "Yes" : "No"}
                  </td>
                  <td
                    className={
                      selectedAttributes.g4_donotphone3rdparty ===
                      selectedChildContact.Fields[
                        "g4_donotphone3rdparty"
                      ]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "g4_donotphone3rdparty",
                        selectedChildContact.Fields[
                          "g4_donotphone3rdparty"
                        ]
                      )
                    }
                  >
                    {selectedChildContact.Fields[
                      "g4_donotphone3rdparty"
                    ]
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Send Third Party Bulk Emails</th>
                  <td
                    className={
                      selectedAttributes.g4_donotbulkemail3rdparty ===
                      state.g4_donotbulkemail3rdparty
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "g4_donotbulkemail3rdparty",
                        state.g4_donotbulkemail3rdparty
                      )
                    }
                  >
                    {state.g4_donotbulkemail3rdparty ? "Yes" : "No"}
                  </td>
                  <td
                    className={
                      selectedAttributes.g4_donotbulkemail3rdparty ===
                      selectedChildContact.Fields[
                        "g4_donotbulkemail3rdparty"
                      ]
                        ? "table-success"
                        : ""
                    }
                    onClick={() =>
                      setSelectedAttributeProperty(
                        "g4_donotbulkemail3rdparty",
                        selectedChildContact.Fields[
                          "g4_donotbulkemail3rdparty"
                        ]
                      )
                    }
                  >
                    {selectedChildContact.Fields[
                      "g4_donotbulkemail3rdparty"
                    ]
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  );
}

export default MergeContactsFieldSelectionTable;
