import lookups from "./lookups";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { ProductBundleFormStages } from "./productBundle";

export const ProductBundlesFormStages = [
  {
    title: "Product Bundles",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4b_singleticket",
              className: "col-bool",
              label: "Is Single Ticket?",
            },
            {
              name: "g4b_displaybreakdown",
              className: "col-bool",
              label: "Display Breakdown?",
            },
            {
              name: "g4b_displayprices",
              className: "col-bool",
              label: "Display Prices?",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/product-bundle",
          lookup: lookups.g4b_productbundle.all,
          subFormStages: ProductBundleFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
