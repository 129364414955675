import React, { useState } from "react";
import {
  AlertStyle,
  ButtonStyle,
  GlobalDispatchMethods,
} from "../../../js/enums";
import { IsBlacklisted, Unsuppress } from "../../../js/service";
import { useGlobalReducer } from "../../../GlobalContext";
import { Alert, Button, Input } from "../../elements/_Elements";

function Blacklisted() {
  const globalDispatch = useGlobalReducer();

  const [formState, setFormState] = useState({
    emailAddress: "",
    isBlacklisted: false,
    isBlacklistedMessage: "",
    isSubmitting: false,
    isUnsuppressed: false,
    isUnsuppressedMessage: "",
    submittedEmailAddress: "",
  });

  const {
    emailAddress,
    isBlacklisted,
    isBlacklistedMessage,
    isSubmitting,
    isUnsuppressed,
    isUnsuppressedMessage,
    submittedEmailAddress,
  } = formState;

  const CheckBlacklist = async () => {
    if (!emailAddress) {
      return;
    }

    let isBlacklistedResponse = false;
    let isBlacklistedMessageResponse = "";

    setFormState({
      ...formState,
      isBlacklisted: false,
      isBlacklistedMessage: "",
      isSubmitting: true,
    });
    try {
      const [serviceResponse] = await Promise.all([
        IsBlacklisted(null, emailAddress),
      ]);

      if (serviceResponse && serviceResponse.data) {
        const { ErrorMessage } = serviceResponse.data;
        if (ErrorMessage) {
          globalDispatch({
            type: GlobalDispatchMethods.AddNotification,
            notification: {
              message: ErrorMessage,
              success: false,
            },
            dispatch: globalDispatch,
          });
        } else {
          isBlacklistedResponse = serviceResponse.data.IsBlacklisted;
          isBlacklistedMessageResponse = isBlacklistedResponse
            ? `${emailAddress} is currently being suppressed`
            : `${emailAddress} is currently not being suppressed`;
        }
      } else {
        globalDispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: {
            message: "Error checking blacklist",
            success: false,
          },
          dispatch: globalDispatch,
        });
      }
    } catch (error) {
      console.error(error);
      globalDispatch({
        type: GlobalDispatchMethods.AddNotification,
        notification: {
          message: error,
          success: false,
        },
        dispatch: globalDispatch,
      });
    } finally {
      setFormState({
        ...formState,
        isBlacklisted: isBlacklistedResponse,
        isBlacklistedMessage: isBlacklistedMessageResponse,
        isSubmitting: false,
        isUnsuppressed: false,
        isUnsuppressedMessage: "",
        submittedEmailAddress: emailAddress,
      });
    }
  };

  const UnsuppressEmailAddress = async () => {
    if (!submittedEmailAddress) {
      return;
    }

    let isUnsuppressedResponse = false;
    let isUnsuppressedMessageResponse = "";

    setFormState({
      ...formState,
      isUnsuppressed: false,
      isUnsuppressedMessage: "",
      isSubmitting: true,
    });
    try {
      const [serviceResponse] = await Promise.all([
        Unsuppress(null, submittedEmailAddress),
      ]);
      if (serviceResponse && serviceResponse.data) {
        const { ErrorMessage } = serviceResponse.data;
        if (ErrorMessage) {
          globalDispatch({
            type: GlobalDispatchMethods.AddNotification,
            notification: {
              message: ErrorMessage,
              success: false,
            },
            dispatch: globalDispatch,
          });
        } else {
          isUnsuppressedResponse = serviceResponse.data.Removed;
          isUnsuppressedMessageResponse = isUnsuppressedResponse
            ? `${emailAddress} has been successfully removed from the suppression list`
            : `Unable to remove ${emailAddress} from the suppression list`;
        }
      } else {
        globalDispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: {
            message: "Error removing blacklist",
            success: false,
          },
          dispatch: globalDispatch,
        });
      }
    } catch (error) {
      console.error(error);
      globalDispatch({
        type: GlobalDispatchMethods.AddNotification,
        notification: {
          message: error,
          success: false,
        },
        dispatch: globalDispatch,
      });
    } finally {
      setFormState({
        ...formState,
        isBlacklisted: false,
        isBlacklistedMessage: "",
        isSubmitting: false,
        isUnsuppressed: isUnsuppressedResponse,
        isUnsuppressedMessage: isUnsuppressedMessageResponse,
      });
    }
  };

  return (
    <>
      <div className="mb-3">
        <Input
          label="Email Address"
          name="emailAddress"
          onChange={(event) =>
            setFormState({
              ...formState,
              emailAddress: event.target.value,
            })
          }
          value={emailAddress}
          disabled={isSubmitting}
        />
      </div>
      <Button
        disabled={isSubmitting}
        onClick={() => CheckBlacklist()}
        showSpinner={isSubmitting}
        style={ButtonStyle.Primary}
        text={"Check Blacklist"}
        type={"button"}
      />
      {isBlacklistedMessage && (
        <>
          {isBlacklisted ? (
            <>
              <Alert
                style={AlertStyle.Warning}
                text={isBlacklistedMessage}
              />
              <Button
                disabled={isSubmitting}
                onClick={() => UnsuppressEmailAddress()}
                showSpinner={isSubmitting}
                style={ButtonStyle.Primary}
                text="Unsuppress"
                type={"button"}
              />
            </>
          ) : (
            <Alert
              style={AlertStyle.Success}
              text={isBlacklistedMessage}
            />
          )}
        </>
      )}
      {isUnsuppressedMessage && (
        <>
          {isUnsuppressed ? (
            <Alert
              style={AlertStyle.Success}
              text={isUnsuppressedMessage}
            />
          ) : (
            <Alert
              style={AlertStyle.Danger}
              text={isUnsuppressedMessage}
            />
          )}
        </>
      )}
    </>
  );
}

export default Blacklisted;
