import { PastYearsSelectHeader } from "../components/elements/_Elements";
import lookups from "./lookups";
import { ProductBundleItemFormStages } from "./productBundleItem";

export const ProductBundleFormStages = [
  {
    title: "Product Bundle",
    sections: [
      {
        name: "General",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_singleticket",
            className: "col-md-6",
            description: "",
            label: "Is Single Ticket?",
            type: "bit",
          },
          {
            name: "g4b_displaybreakdown",
            className: "col-md-6",
            description: "",
            label: "Display Breakdown?",
            type: "bit",
          },
          {
            name: "g4b_displayprices",
            className: "col-md-6",
            description: "",
            displayIfFromValues: (values) =>
              values && values.g4b_displaybreakdown,
            label: "Display Prices?",
            type: "bit",
          },
          {
            name: "g4b_parentproductbundleitemid",
            className: "col-md-6",
            description: "",
            disabledIfFromState: (state) => {
              if (!state || !state.id || !state.relatedEntities) {
                return true;
              }

              let bundleItems = state.relatedEntities.find(
                (r) => r.entityName === "g4b_productbundleitem"
              );

              if (
                !bundleItems ||
                !bundleItems.entities ||
                bundleItems.entities.length === 0
              ) {
                return true;
              }

              return !bundleItems.entities.some(
                (i) => i.Fields && i.Fields.statuscode === 1
              );
            },
            label: "Parent Product Bundle Item",
            lookup: lookups.g4b_productbundleitem.all,
            mapOptions: (_, state) => {
              //Have the options for this field be based on the associated product bundle items in the related entity
              return (
                state &&
                state.relatedEntities &&
                state.relatedEntities
                  .find(
                    (r) => r.entityName === "g4b_productbundleitem"
                  )
                  .entities.filter(
                    (i) => i.Fields && i.Fields.statuscode === 1
                  )
                  .map((b) => {
                    return {
                      Key: b.Id,
                      Value: b.Fields.g4b_productbaseidname,
                    };
                  })
                  .sort((a, b) => {
                    return a.Value.localeCompare(b.Value);
                  })
              );
            },
            showErrorsBeforeTouched: true,
            type: "lookup",
            validate: (_, __, state) => {
              let bundleItems = [];

              if (state && state.relatedEntities) {
                bundleItems = state.relatedEntities
                  .find(
                    (r) => r.entityName === "g4b_productbundleitem"
                  )
                  .entities.filter(
                    (i) => i.Fields && i.Fields.statuscode === 1
                  )
                  .map((b) => {
                    return {
                      Key: b.Id,
                      Value: b.Fields.g4b_productbaseidname,
                    };
                  });
              }

              if (bundleItems.length === 0) {
                return "There are no product bundle items for the selected product bundle.";
              }

              return "";
            },
          },
        ],
      },
      {
        name: "Bundle Items",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_productbaseidname",
              className: "col-name",
              label: "Product",
            },
            {
              name: "g4b_defaultvarianttypeidname",
              className: "col-name",
              label: "Default Variant Type",
            },
            {
              name: "g4b_fixedprice",
              className: "col-number",
              label: "Fixed Price",
              type: "money",
            },
            {
              name: "g4b_percentage",
              className: "col-number",
              label: "Percentage",
            },
            {
              name: "g4b_fixedrevenueshare",
              className: "col-bool",
              label: "Fixed Revenue Share?",
            },
            {
              name: "g4b_collectgiftaid",
              className: "col-bool",
              label: "Collect Gift Aid?",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["g4b_productbaseidname"],
              type: "text",
            },
            {
              name: "g4b_defaultvarianttypeidname",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4b_productbaseidname",
          initialOrderedByAsc: true,
          linkForNewRecord: "/product-bundle-item",
          linkedEntityId: "g4b_productbundleid",
          loadStateRelatedEntityName: "g4b_productbundleitem",
          lookup: lookups.g4b_productbundleitem.all,
          subFormStages: ProductBundleItemFormStages,
          useAlphabetPagination: true,
          useSubForm: true,
        },
      },
    ],
  },
];
