import { Button, Input, SelectWithFiltering } from "./_Elements";
import { ButtonStyle } from "../../js/enums";

const handleSearchFilterChange = (
  event,
  filter,
  filters,
  setFilters
) => {
  setFilters([
    ...filters.filter((f) => f.Key !== filter.name),
    {
      Key: filter.name,
      FilterAction: filter.filterAction,
      Type: filter.type,
      Value:
        filter.type === "bit"
          ? event.target.checked
          : filter.type === "select"
          ? event.filter((e) => e.label).length > 0 //It is assumed if the event has a label, then its got a value
            ? event.filter((e) => e.label).map((e) => e.value) //This is because if e.value was = 0, then it wouldn't count it in the filter
            : ""
          : event.target.value,
    },
  ]);
};

const getSearchTableFilters = (
  filters,
  handleSubmitFilter,
  isSubmitting,
  searchFilterArray,
  setFilters,
  state
) => {
  return (
    <div className="row mb-3">
      {searchFilterArray.map((filter, i) => {
        var filterType = "text";
        if (filter.type !== null && filter.type !== undefined) {
          filterType = filter.type;
        }
        return filterType === "text" ? (
          <div className="col-md-2" key={i}>
            <Input
              label={filter.label}
              name={filter.name}
              onChange={(event) =>
                handleSearchFilterChange(
                  event,
                  filter,
                  filters,
                  setFilters
                )
              }
              description={filter.description}
              type={"text"}
              value={
                filters.some((f) => f.Key === filter.name)
                  ? filters.find((f) => f.Key === filter.name).Value
                  : ""
              }
            />
          </div>
        ) : filterType === "bit" ? (
          <div className="col-md-2" key={i}>
            <Input
              label={filter.label}
              name={filter.name}
              onChange={(event) =>
                handleSearchFilterChange(
                  event,
                  filter,
                  filters,
                  setFilters
                )
              }
              description={filter.description}
              type={"bit"}
              checked={
                filters.some((f) => f.Key === filter.name)
                  ? filters.find((f) => f.Key === filter.name).Value
                  : ""
              }
            />
          </div>
        ) : (
          <div className="col-md-2" key={i}>
            <SelectWithFiltering
              label={filter.label}
              isMulti={true}
              mandatory={false}
              name={filter.name}
              options={
                !filter.mapOptionGroups
                  ? Object.entries(filter.enum).map(
                      ([Name, Number]) => ({
                        Key: Number,
                        Value: Name,
                      })
                    )
                  : null
              }
              optionGroups={
                filter.mapOptionGroups
                  ? filter.mapOptionGroups(null, state)
                  : []
              }
              onChange={(event) =>
                handleSearchFilterChange(
                  event,
                  filter,
                  filters,
                  setFilters
                )
              }
              placeholder={"Please select"}
              value={
                filters.some((f) => f.Key === filter.name)
                  ? filters.find((f) => f.Key === filter.name).Value
                  : ""
              }
            />
          </div>
        );
      })}
      {searchFilterArray.length > 0 && (
        <div className="col-md-4">
          <div className="form-label-spacer"></div>
          <Button
            disabled={isSubmitting}
            type={"button"}
            showSpinner={isSubmitting}
            style={ButtonStyle.Info}
            text={"Clear Filters"}
            onClick={() => {
              setFilters([]);
            }}
          />
          <Button
            className={"ms-3"}
            disabled={isSubmitting}
            type={"submit"}
            showSpinner={isSubmitting}
            style={ButtonStyle.Primary}
            text={"Submit"}
            onClick={() => {
              handleSubmitFilter();
            }}
          />
        </div>
      )}
    </div>
  );
};

export { getSearchTableFilters };
