import lookups from "./lookups";

export const CrossSellProductFormStages = [
  {
    title: "Cross Sell Product",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-4",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_parentproduct",
            className: "col-md-4",
            description:
              "This represents the product being added to the basket to trigger the cross sell products being displayed",
            label: "Parent Cross Sell Product",
            lookup: lookups.g4b_productbase.all,
            required: true,
            type: "lookup",
          },
          {
            name: "g4b_childproduct",
            className: "col-md-4",
            description:
              "This represents the cross sell product being offered upon the parent product being added",
            label: "Child Cross Sell Product",
            lookup: lookups.g4b_productbase.all,
            required: true,
            type: "lookup",
          },
        ],
      },
    ],
  },
];
