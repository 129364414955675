import React from "react";
import { ButtonStyle } from "../../../js/enums";
import {
  Button,
  Card,
  FormDatePicker,
  PastYearsSelectHeader,
} from "../../elements/_Elements";
import { createDateFromToday, isSameOrLaterDate } from "../../../js/utility";

export default function JournalDailyReportsDatePicker({
  handleSubmitFilter,
  isSubmitting,
  selectedStartDate,
  setSelectedStartDate,
}) {
  return (
    <Card className="mb-3">
      <div className="row g-3">
        <div className="col-md-2">
          <FormDatePicker
            dateFormat={"dd/MM/yyyy"}
            handleDateChange={(date) => {
              setSelectedStartDate(date);
            }}
            label={"Choose Date"}
            locale={"en-GB"}
            mandatory={true}
            name={"date-picker"}
            selected={selectedStartDate}
            renderCustomHeader={PastYearsSelectHeader}
            filterDate={(date) => {
              return !isSameOrLaterDate(date,
                createDateFromToday(-1, 0, 0));
            }}
          />
        </div>
        <div className="col-md-10 align-self-center">
          <Button
            disabled={!selectedStartDate || isSubmitting}
            type={"submit"}
            showSpinner={isSubmitting}
            style={ButtonStyle.Primary}
            text={"Submit"}
            onClick={() => {
              handleSubmitFilter();
            }}
          />
        </div>
      </div>
    </Card>
  );
}
