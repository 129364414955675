import React, { useEffect, useState } from "react";
import { Input } from "../../elements/_Elements";

function YesNoDefaultValue({ setFieldValue, state, values }) {
  const [initialLoad, setInitialLoad] = useState(true);
  const [defaultValueSelected, setDefaultValueSelected] =
    useState(false);

  useEffect(() => {
    if (initialLoad) {
      //Need to check that values.g4b_defaultvalue has a value of "true". If it doesn't
      //then its assumed that we have switched the type from Text to Yes/No, meaning we should clear out
      //the values.g4b_defaultvalue value
      if (
        values.g4b_defaultvalue &&
        values.g4b_defaultvalue === "true"
      ) {
        setDefaultValueSelected(true);
      } else {
        setDefaultValueSelected(false);
        setFieldValue("g4b_defaultvalue", null);
      }
      setInitialLoad(false);
    }
  }, [
    initialLoad,
    state.id,
    values.updateAction,
    setFieldValue,
    values.g4b_defaultvalue,
    values.g4b_modifieroptions,
  ]);

  return (
    <div className="row mb-3 ms-0">
      <Input
        label={"Yes/No Default Value"}
        className={"col-md-4"}
        name={"yes-no-default-value"}
        description={
          "Check this box to set the modifier to be set to true by default"
        }
        onChange={(event) => {
          setDefaultValueSelected(event.target.checked);
          setFieldValue(
            "g4b_defaultvalue",
            event.target.checked ? "true" : null
          );
        }}
        type={"bit"}
        checked={defaultValueSelected}
      />
    </div>
  );
}

export default YesNoDefaultValue;
