import { PastYearsSelectHeader } from "../components/elements/_Elements";
import lookups from "./lookups";
import { PriceBreakFormStages } from "./priceBreak";

export const PriceBreakGroupFormStages = [
  {
    title: "Price Break Group",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Price Breaks",
        table: {
          name: "Products",
          allowAdd: true,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_productidname",
              className: "col-name",
              label: "Product",
            },
            {
              name: "g4b_quantity",
              className: "col-number",
              label: "Quantity",
              type: "string",
            },
            {
              name: "g4b_discountpercentage",
              className: "col-number",
              label: "Discount Percentage",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/price-break",
          linkedEntityId: "g4b_pricebreakgroupid",
          loadStateRelatedEntityName: "g4b_pricebreak",
          lookup: lookups.g4b_pricebreak.all,
          useAlphabetPagination: true,
          subFormStages: PriceBreakFormStages,
          useSubForm: false,
        },
      },
    ],
  },
];
