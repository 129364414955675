const fieldName = "productVariants";

const handlePeakPriceChange = (
  variantTypeId,
  variantPriceListId,
  peakPrice,
  productVariants,
  handleChange
) => {
  const newValue = productVariants.map((productVariant) => {
    if (productVariant.VariantTypeId === variantTypeId) {
      // Update the price change for the matching variant type
      return {
        ...productVariant,
        PriceListItems: productVariant.PriceListItems.map(
          (priceListItem) => {
            if (
              priceListItem.VariantPriceListId === variantPriceListId
            ) {
              return {
                ...priceListItem,
                PeakPrice: peakPrice,
              };
            }
            return priceListItem;
          }
        ),
      };
    }
    // For all other variant types, return the original value
    return productVariant;
  });

  handleChange({
    target: {
      name: fieldName,
      value: newValue,
    },
  });
};

const handleProductVariantChange = (
  propertyName,
  value,
  variantTypeId,
  productVariants,
  handleChange
) => {
  const newValue = productVariants.map((productVariant) => {
    if (productVariant.VariantTypeId === variantTypeId) {
      return {
        ...productVariant,
        Code: propertyName === "code" ? value : productVariant.Code,
        MandatoryVariant:
          propertyName === "mandatoryVariant"
            ? value
            : productVariant.MandatoryVariant,
        MarketingListId:
          propertyName === "marketingListId"
            ? value
            : productVariant.MarketingListId,
        NotForSale:
          propertyName === "notForSale"
            ? value
            : productVariant.NotForSale,
        TaxCodeId:
          propertyName === "taxCodeId"
            ? value
            : productVariant.TaxCodeId,
      };
    }
    return productVariant;
  });

  handleChange({
    target: {
      name: fieldName,
      value: newValue,
    },
  });
};

const handleOffPeakPriceChange = (
  variantTypeId,
  variantPriceListId,
  offPeakPrice,
  productVariants,
  usePeakPrice,
  handleChange
) => {
  const newValue = productVariants.map((productVariant) => {
    if (productVariant.VariantTypeId === variantTypeId) {
      // Update the price change for the matching variant type
      return {
        ...productVariant,
        PriceListItems: productVariant.PriceListItems.map(
          (priceListItem) => {
            if (
              priceListItem.VariantPriceListId === variantPriceListId
            ) {
              return {
                ...priceListItem,
                PeakPrice: usePeakPrice
                  ? priceListItem.PeakPrice
                  : offPeakPrice,
                OffPeakPrice: offPeakPrice,
              };
            }
            return priceListItem;
          }
        ),
      };
    }
    // For all other variant types, return the original value
    return productVariant;
  });

  handleChange({
    target: {
      name: fieldName,
      value: newValue,
    },
  });
};

const handleVariantPriceListChange = (
  variantPriceListId,
  productVariants,
  handleChange
) => {
  const newValue = productVariants.map((productVariant) => {
    let newPriceListItems = [...productVariant.PriceListItems];
    newPriceListItems.push({
      OffPeakPrice: "",
      PeakPrice: "",
      VariantPriceListId: variantPriceListId,
      VariantTypeId: productVariant.VariantTypeId,
    });

    return {
      ...productVariant,
      PriceListItems: newPriceListItems,
    };
  });

  handleChange({
    target: {
      name: fieldName,
      value: newValue,
    },
  });
};

export {
  handlePeakPriceChange,
  handleProductVariantChange,
  handleOffPeakPriceChange,
  handleVariantPriceListChange,
};
