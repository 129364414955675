import AccessControlProviderHasError from "../components/form/accessControlProvider/accessControlProviderHasError";
import { DefaultCommunicationMethods, Providers } from "../js/enums";

export const AccessControlProviderFormStages = [
  {
    title: "Access Control Provider",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-4",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_telegramdestination",
            className: "col-md-4",
            description: "",
            label: "Telegram Destination",
            type: "nvarchar",
            required: true,
          },
          {
            name: "g4b_provider",
            className: "col-md-4",
            label: "Provider",
            type: "picklist",
            enum: Providers,
            required: true,
          },
          {
            name: "g4b_communicationmethod",
            className: "col-md-4",
            label: "Communication Method",
            type: "picklist",
            enum: DefaultCommunicationMethods,
            required: true,
          },
          {
            name: "g4b_batchsize",
            className: "col-md-4",
            label: "Batch Size",
            type: "int",
          },
          {
            name: "g4b_expirydays",
            className: "col-md-4",
            label: "Expiry Days",
            type: "int",
          },
          {
            name: "g4b_issuerid",
            className: "col-md-4",
            label: "Issuer Id",
            type: "nvarchar",
          },
          {
            name: "g4b_venueid",
            className: "col-md-4",
            label: "Venue Id",
            type: "nvarchar",
          },
          {
            name: "g4b_firstsequencenumber",
            className: "col-md-4",
            label: "First Sequence Number",
            type: "int",
          },
          {
            name: "g4b_daysbeforeevent",
            className: "col-md-4",
            label: "Days Before Event",
            type: "int",
          },
          {
            name: "g4b_errorwait",
            className: "col-md-4",
            label: "Error Wait",
            type: "int",
          },
          {
            name: "g4b_username",
            className: "col-md-4",
            label: "Username",
            type: "nvarchar",
          },
          {
            name: "g4b_password",
            className: "col-md-4",
            label: "Password",
            type: "nvarchar",
          },
          {
            name: "g4b_requestaccessinterval",
            className: "col-md-4",
            label: "Request Access Interval",
            type: "int",
          },
          {
            name: "g4b_receiverid",
            className: "col-md-4",
            label: "Receiver ID",
            type: "nvarchar",
          },
          {
            name: "g4b_port",
            className: "col-md-4",
            label: "Port",
            type: "nvarchar",
          },
          {
            name: "g4b_organisation",
            className: "col-md-4",
            label: "Organisation",
            type: "nvarchar",
          },
          {
            name: "g4b_lasttransactionnumber",
            className: "col-md-4",
            label: "Last Transaction Number",
            type: "nvarchar",
          },
          {
            name: "g4b_installno",
            className: "col-md-4",
            label: "Install number",
            type: "nvarchar",
          },
          {
            name: "g4b_keepaliveinterval",
            className: "col-md-4",
            label: "Keep Alive Interval",
            type: "int",
          },
          {
            name: "g4b_maxretries",
            className: "col-md-4",
            label: "Max Retries",
            type: "int",
          },
          {
            name: "g4b_telegramsequencenumber",
            className: "col-md-4",
            label: "Telegram Sequence Number",
            type: "int",
          },
          {
            name: "g4b_telegramqueuesminsbeforereprocessing",
            className: "col-md-4",
            label: "Telegram Queues Mins Before Reprocessing",
            type: "int",
          },
          {
            name: "g4b_loglocation",
            className: "col-md-4",
            label: "Log Location",
            type: "nvarchar",
          },
          {
            name: "g4b_sendevents",
            className: "col-md-4",
            label: "Send Events",
            type: "bit",
          },
          {
            name: "g4b_batchupdate",
            className: "col-md-4",
            label: "Batch Update Sendtime",
            type: "bit",
          },
          {
            name: "g4b_logdebuginformation",
            className: "col-md-4",
            label: "Log Debug Information",
            type: "bit",
          },
          {
            name: "g4b_alwayssendwhitelists",
            className: "col-md-4",
            label: "Always Send Whitelists",
            type: "bit",
          },
          {
            name: "g4b_requestaccessinformation",
            className: "col-md-4",
            label: "Request Access Information",
            type: "bit",
          },
          {
            name: "g4b_sendtelegrams",
            className: "col-md-4",
            label: "Send Telegrams",
            type: "bit",
          },
          {
            name: "g4b_processresponses",
            className: "col-md-4",
            label: "Process Responses",
            type: "bit",
          },
          {
            name: "g4b_sendprofilephoto",
            className: "col-md-4",
            label: "Send Profile Photo ",
            type: "bit",
          },
          {
            name: "g4b_usemessagequeue",
            className: "col-md-4",
            label: "Use Message Queue",
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Access Control Status",
    id: "access-control-status",
    editOnOverview: true,
    sections: [
      {
        name: "",
        fields: [
          { name: "g4a_accesscontrolstatusid", hidden: true },
          { name: "g4a_name", hidden: true },
          { name: "g4a_haserror", hidden: true, type: "bit" },
          {
            name: "ProviderHasError",
            className: "col-md-6",
            component: AccessControlProviderHasError,
            type: "component",
          },
          {
            name: "g4a_lasterror",
            className: "col-md-6",
            disabled: true,
            label: "Last Error",
            type: "nvarchar",
          },
          {
            name: "g4a_lastiteration",
            className: "col-md-6",
            disabled: true,
            label: "Last Iteration",
            type: "datetime",
            showTimeSelect: true,
            dateFormat: "dd/MM/yyyy HH:mm",
          },
          {
            name: "g4a_step",
            className: "col-md-6",
            disabled: true,
            label: "Step",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
