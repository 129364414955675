import React, { useCallback, useEffect, useState } from "react";
import { AlertStyle, ButtonStyle } from "../../../js/enums";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import { GetEntityRecordById, Update } from "../../../js/service";
import { DispatchMethods } from "../enums";
import { Alert, Button } from "../../elements/_Elements";

function ReprocessTelegramQueue({ state }) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [triggerTelegramQueueReload, setTriggerTelegramQueueReload] =
    useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const { g4b_stage } = state;

  const reprocess = async () => {
    setIsSubmitting(true);
    try {
      const successMessage = "Record reprocessed";
      const [serviceResponse] = await Promise.all([
        Update(
          globalDispatch,
          successMessage,
          {
            Id: state.id,
            Name: state.g4b_name,
            Attributes: { g4b_stage: 1 },
          },
          "g4b_telegramqueue"
        ),
      ]);
      if (serviceResponse && serviceResponse.data) {
        dispatch({
          type: DispatchMethods.UpdateRecord,
          values: { g4b_stage: 1, g4b_stagename: "Unprocessed" },
        });
        setIsSubmitting(false);
        setDisplayAlert(true);
        setTriggerTelegramQueueReload(true);
      }
    } catch (error) {
      console.error(error);
      setDisplayAlert(false);
      setIsSubmitting(false);
    }
  };

  const reloadTelegramQueue = useCallback(async () => {
    try {
      const [serviceResponse] = await Promise.all([
        GetEntityRecordById(
          globalDispatch,
          "g4b_telegramqueue",
          state.id
        ),
      ]);
      if (serviceResponse && serviceResponse.data) {
        //Set the state g4b_stage value and g4b_stagename to the value returned from the services
        //If its still unprocessed or processing then leave the timer running to auto refresh so that it can be ran again
        dispatch({
          type: DispatchMethods.UpdateRecord,
          values: serviceResponse.data.Fields,
        });

        //Also update the related entity record for g4b_telegram
        var updatedStateRelatedEntities = state.relatedEntities;
        var updatedStateRelatedEntity =
          updatedStateRelatedEntities.find(
            (r) => r.entityName === "g4b_telegram"
          );

        var matchingResponseRelatedEntity =
          serviceResponse.data.RelatedEntities.find(
            (r) => r.EntityName === "g4b_telegram"
          );
        var highlightRelatedEntities = [];
        if (
          updatedStateRelatedEntity &&
          matchingResponseRelatedEntity
        ) {
          //Add list of new records to the highlightRelatedEntities array so it can be highlighted to indicate that its new
          let newRecords =
            matchingResponseRelatedEntity.Entities.filter(
              (d) =>
                !updatedStateRelatedEntity.entities.some(
                  (r) => r.Id === d.Id
                )
            );
          newRecords.forEach((newRecord) => {
            highlightRelatedEntities.push({
              entityName: "g4b_telegram",
              id: newRecord.Id,
            });
          });
          updatedStateRelatedEntity.entities =
            matchingResponseRelatedEntity.Entities;
        }

        dispatch({
          type: DispatchMethods.SetRelatedEntities,
          relatedEntities: updatedStateRelatedEntities,
          highlightRelatedEntities: highlightRelatedEntities,
        });

        if (
          serviceResponse.data.Fields.g4b_stage !== 1 &&
          serviceResponse.data.Fields.g4b_stage !== 2
        ) {
          setTriggerTelegramQueueReload(false);
          setDisplayAlert(false);
        }
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
    }
  }, [
    dispatch,
    globalDispatch,
    state.id,
    state.relatedEntities,
    timeoutId,
  ]);

  useEffect(() => {
    if (triggerTelegramQueueReload) {
      //Wait 10 seconds before making ticketing call to reload telegram queue
      //as there may be a gap in time between the call to reprocess the telegram being sent
      //and telegram queue being reprocessed
      let timeoutIdValue = setTimeout(() => {
        reloadTelegramQueue();
      }, 10000);
      if (!timeoutId) {
        setTimeoutId(timeoutIdValue);
      }
    }
  }, [
    dispatch,
    globalDispatch,
    reloadTelegramQueue,
    state.id,
    state.relatedEntities,
    timeoutId,
    triggerTelegramQueueReload,
  ]);

  return (
    <>
      <Button
        className="text-decoration-none"
        style={ButtonStyle.Primary}
        text={"Reprocess"}
        onClick={() => reprocess()}
        showSpinner={isSubmitting}
        disabled={isSubmitting || g4b_stage === 1}
      />
      {displayAlert && (
        <Alert
          className="text-center m-0 mt-3"
          style={AlertStyle.Info}
          text={"Reprocessing Telegrams"}
        />
      )}
    </>
  );
}

export default ReprocessTelegramQueue;
