import { PastYearsSelectHeader } from "../components/elements/_Elements";
import { DonationStatus, TelegramQueueStage } from "../js/enums";
import { DonationFormStages } from "./donation";
import lookups from "./lookups";
import { MembershipAccessTokenFormStages } from "./membershipAccessToken";
import { TelegramQueueFormStages } from "./telegramQueue";

export const MembershipFormStages = [
  {
    title: "Membership",
    id: "membership",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "name",
            className: "col-md-4",
            description: "",
            isNameField: true,
            label: "Name",
            type: "nvarchar",
          },
          {
            name: "g4m_membershipnumber",
            description: "",
            label: "Membership Number",
            type: "nvarchar",
          },
          {
            name: "g4m_memberid",
            description:
              "The contact who is the beneficiary of this membership",
            label: "Member",
            type: "lookup",
            lookup: lookups.contact.all,
          },
          {
            name: "g4m_membershipaccountid",
            description: "",
            label: "Membership Account",
            type: "lookup",
            lookup: lookups.g4m_membershipaccount.all,
          },
          {
            name: "g4m_leadmembershipid",
            description:
              "Lead Membership associated with Membership.",
            label: "Lead Membership",
            type: "lookup",
            lookup: lookups.g4m_membership.all,
          },
          {
            name: "g4m_ownerid",
            description:
              "The contact who is the owner of the of the membership",
            label: "Owner",
            type: "lookup",
            lookup: lookups.contact.all,
          },
        ],
      },
    ],
  },
  {
    title: "Product Details",
    id: "product-details",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4m_productid",
            description: "The bookable product",
            label: "Bookable Product",
            type: "lookup",
            lookup: lookups.g4b_productbase.all,
          },
          {
            name: "g4m_varianttypeid",
            description: "The Variant Type",
            label: "Variant Type",
            type: "lookup",
            lookup: lookups.g4b_varianttype.all,
          },
          {
            name: "g4m_parentvarianttypeid",
            description: "The Parent Variant Type",
            label: "Parent Variant Type",
            type: "lookup",
            lookup: lookups.g4b_varianttype.all,
          },
          {
            name: "g4m_membershippaymentplanid",
            description:
              "Membership Payment Plan associated with Membership.",
            label: "Membership Payment Plan",
            type: "lookup",
            lookup: lookups.g4m_membershippaymentplan.all,
          },
        ],
      },
    ],
  },
  {
    title: "Dates",
    id: "dates",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4m_startdate",
            className: "col-md-3",
            description: "The date the membership will start",
            label: "Start Date",
            type: "datetime",
            dateFormat: "dd/MM/yyyy",
          },

          {
            name: "g4m_initialperiodenddate",
            className: "col-md-3",
            description: "",
            label: "Initial Period End Date",
            type: "datetime",
            dateFormat: "dd/MM/yyyy",
          },
          {
            name: "g4m_cancellationdate",
            className: "col-md-3",
            description: "",
            label: "Cancellation Date",
            type: "datetime",
            dateFormat: "dd/MM/yyyy",
          },
          {
            name: "g4m_enddate",
            className: "col-md-6",
            description: "",
            label: "End Date",
            type: "datetime",
            dateFormat: "dd/MM/yyyy",
          },
          {
            name: "g4m_enddatetriggergeneratedon",
            className: "col-md-6",
            description: "",
            label: "End Date Trigger Generated On",
            type: "datetime",
            dateFormat: "dd/MM/yyyy",
          },
          {
            name: "g4m_renewaldate",
            className: "col-md-6",
            description: "",
            label: "Renewal Date",
            type: "datetime",
            dateFormat: "dd/MM/yyyy",
          },
          {
            name: "g4m_renewaldatetriggergeneratedon",
            className: "col-md-6",
            description: "",
            label: "Renewal Date Trigger Generatedon",
            type: "datetime",
          },
        ],
      },
    ],
  },
  {
    title: "Other",
    id: "other",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4m_mobilewalletmembershipid",
            description:
              "The mobile membership id from a third party vendor",
            label: "Mobile Wallet Membership Id",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Telegram Queues",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_sequencenumber",
              label: "Sequence Number",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_type",
              label: "Type",
              className: "col-short-name",
            },
            {
              name: "g4b_stage",
              className: "col-name",
              label: "Stage",
              type: "picklist",
              enum: TelegramQueueStage,
            },
            {
              name: "g4b_accesscontrolprovideridname",
              label: "Access Control Provider",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4b_telegramqueue",
          lookup: lookups.g4b_telegramqueue.all,
          linkForNewRecord: "/telegram-queue",
          subFormStages: TelegramQueueFormStages,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Membership Access Tokens",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          fields: [
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4m_membershipaccesstoken",
          lookup: lookups.g4m_membershipaccesstoken.all,
          linkForNewRecord: "/membership-access-token",
          subFormStages: MembershipAccessTokenFormStages,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Donations",
    id: "donations",
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          fields: [
            {
              name: "g4b_bookingidname",
              className: "col-short-name",
              label: "Booking",
              type: "string",
            },
            {
              name: "g4d_declarationidname",
              className: "col-short-name",
              label: "Declaration",
              type: "string",
            },
            {
              name: "g4d_fundidname",
              className: "col-short-name",
              label: "Fund",
              type: "string",
            },
            {
              name: "g4d_donationstatus",
              className: "col-short-name",
              label: "Donation Status",
              type: "statusbadge",
              enum: DonationStatus,
            },
            {
              name: "g4d_donationvalue",
              className: "col-short-name",
              label: "Donation value",
              type: "money",
            },
            {
              name: "g4d_giftaidvalue",
              className: "col-short-name",
              label: "Gift Aid Value",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4d_donation",
          lookup: lookups.g4d_donation.all,
          subFormStages: DonationFormStages,
          useSubForm: true,
        },
      },
    ],
  },
];
